import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

class NavItem extends Component {
    render (){
        return (
            <li className="nav-item">
                <NavLink to={this.props.to} className="nav-link">
                    <i className={`fe fe-${this.props.icon}`}></i> <span>{this.props.name}</span>
                </NavLink>
            </li>
        );
    }
}

export default NavItem;