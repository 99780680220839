import React, { Component } from 'react';
import {Link} from "react-router-dom";



class Expenses extends Component {
    state = {
        loading: true,
        search: "",
        data: []
    }
    onSearch = (event) => {
        const {databaseRequest}=this.props;
        this.setState({loading: true});
        var search = event.target.value;
        databaseRequest(`/account/expenses?CompanyName:like=%${search}%`,"GET").then((data)=>{
                this.setState({
                    loading: false,
                    data: data.length>0?data:[]
                })
        });
    }
    siteName = (Id) =>{
        const {sites} = this.state;
        for(let i=0; i<sites.length; i++){
            if(Id==sites[i].Id){
                return sites[i].Name;
            }
        }
        return "Not allocated";
    }
    componentDidMount = () =>{
        const {databaseRequest,setPage}=this.props;
        setPage(`Expenses`);
        let sites = [];
        databaseRequest("/system/sites","GET").then((data)=>{ 
            sites = [...data];
            return databaseRequest("/account/expenses","GET");
        }).then((data)=>{
            this.setState({
                loading: false,
                data: data.length>0?data:[],
                sites: sites
            })

        });
    }
    timeAgo = (elapsed) => {
        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;
        if (elapsed < msPerMinute) {
            return Math.round(elapsed/1000) + ' seconds ago';   
        }

        else if (elapsed < msPerHour) {
            return Math.round(elapsed/msPerMinute) + ' minutes ago';   
        }

        else if (elapsed < msPerDay ) {
            return Math.round(elapsed/msPerHour ) + ' hours ago';   
        }

        else if (elapsed < msPerMonth) {
            return Math.round(elapsed/msPerDay) + ' days ago';   
        }

        else if (elapsed < msPerYear) {
            return  Math.round(elapsed/msPerMonth) + ' months ago';   
        }

        else {
            return Math.round(elapsed/msPerYear ) + ' years ago';   
        }
    }
    amount = (row) =>{
        if(row.Reconciled==1){
            return (<td><i>Reconciled</i><del className="float-right">&#36;{(row.Amount?row.Amount:0).toFixed(2)}</del></td>);
        }
        else{
            return (<td><i className="float-right">&#36;{(row.Amount?row.Amount:0).toFixed(2)}</i></td>);
        }
    }
    table = () => {    
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading Expenses...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    <table className="table table-striped">
                    <thead className="thead-dark">
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Company</th>
                        <th scope="col" className="d-none d-md-table-cell">Description</th>
                        <th scope="col" className="d-none d-md-table-cell">Date</th>
                        <th scope="col" className="text-right">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.data.map((row, i) => {
                        return (
                        <tr key={i}>
                            <th scope="row" ><Link to={`/account/expenses/${row.Id}`}>{1+i}</Link></th>
                            <td><Link to={`/account/expenses/${row.Id}`}>{row.CompanyName}</Link></td>
                            <td className="d-none d-md-table-cell">{row.Description.length>20?row.Description.substr(0,17)+"...":row.Description}</td>
                            <td className="d-none d-md-table-cell">{row.DateTime?this.timeAgo(new Date().getTime()-row.DateTime):"-"}</td>
                            {this.amount(row)}
                        </tr>)
                    })}
                    </tbody>
                    </table>
                )
            }
    }

    render(){
        return (
            <main role="main" className="container">
            <div className="p-5">
                <h2><i className="fe fe-dollar-sign"></i> Expenses</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <form className="form-inline">
                            <input className="form-control mr-sm-2 d-none d-md-table-cell" type="search" onChange={this.onSearch} placeholder="Search" aria-label="Search"/>                      
                        </form>
                    </div>
                    <div className="col p-0">
                        <Link to="/account/expenses/-1"><button type="button" className="btn btn-success float-right">New Expense</button></Link>
                    </div>
                </div>
                <div className="row">
                    {this.table()}

                </div>
            </div>
            </main>
            
        )
    }
}
export default Expenses;