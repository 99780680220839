import React, { Component, createRef } from 'react';
import { Link } from "react-router-dom";
import socketIOClient from "socket.io-client";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import option from '../Dashboards/GaugeOptions.js';
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);

class Dashboard extends Component {
    state = {
        loading: true,
        data: [],
        updateInt: null,
        Temp: 0,
        Rinsed: 0,
        Total: 0,   
        dimensions: {width:0,height:0}
    }
    age = (values) =>{
        var maxAge = 10000000000;
        for (let i = 0; i < values.length; i++) {
            if (maxAge > values[i].Age) {
                maxAge = values[i].Age;
            }
        }
        return maxAge;
    }
    getValue = (tag, values) => {
        for (let i = 0; i < values.length; i++) {
            if (tag == values[i].Tag) {
                return values[i];
            }
        }
        return [];
    }


    page = () => {
        const {Site,Species} = this.props;
        var options = {
            chart: {
                type: 'line',
                backgroundColor: '#ffffff'
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            title: {
                text: 'Previous 2 Weeks Rinsing'
            },
            subtitle: {
                text: 'Australia'
            },
            xAxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            },
            yAxis: {
                title: {
                    text: 'Rinsed'
                }
            },
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: false
                }
            },
            series: [{
                name: 'Kyneton',
                data: [7.0, 6.9, 9.5, 14.5, 18.4, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6]
            }, {
                name: 'Frederickton',
                data: [3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0, 16.6, 14.2, 10.3, 6.6, 4.8]
            }, {
                name: 'Seymour',
                data: [13.9, 14.2, 15.7, 18.5, 111.9, 115.2, 117.0, 116.6, 114.2, 110.3, 16.6, 14.8]
            }]
        };
        return (
            <div className="shadow bg-white rounded p-3 h-100 flex-grow" style={{overflow: 'hidden'}} ref={el => (this.container = el)}>
            <HighchartsReact
                containerProps={{ style: {height: '80%'}}}
                options={options}
                highcharts={Highcharts}
            />
            </div>
        );
    }
    render() {
        return this.page();
    }
    connectionQuality = (elapsed) => {
        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 60; //Two months
        var msPerYear = msPerDay * 365*2; //Two years
        if(elapsed <= 0){
            return "Not Connected"
        }
        if(elapsed < 10000){
            return "";
        }
        if(elapsed < 31000){
            return "Connected: Not Polling";
        }
        if (elapsed < msPerMinute) {
            return `Warning: Updated ${Math.round(elapsed/1000)} second${Math.round(elapsed/1000)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerHour) {
            return `Warning: Updated ${Math.round(elapsed/msPerMinute)} minute${Math.round(elapsed/msPerMinute)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerDay ) {
            return `Warning: Updated ${Math.round(elapsed/msPerHour)} hour${Math.round(elapsed/msPerHour)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerMonth) {
            return `Warning: Updated ${Math.round(elapsed/msPerDay)} day${Math.round(elapsed/msPerDay)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerYear) {
            return `Warning: Updated ${Math.round(elapsed/msPerMonth)} month${Math.round(elapsed/msPerMonth)>1?"s":""} ago`;   
        }

        else {
            return `Warning: Updated ${Math.round(elapsed/msPerYear)} year${Math.round(elapsed/msPerYear)>1?"s":""} ago`;   
        }
    }
    DINTtoLongDate = function(dint){
        if(typeof dint==='undefined'){
            return "";
        }
        var dd=Number(this.pad(dint).substr(6,2));
        var mm=Number(this.pad(dint).substr(4,2));
        var yyyy=Number(this.pad(dint).substr(0,4));
    
        var month = new Array();
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";
        
        return month[mm-1]+" "+this.ordinal_suffix_of(dd)+", "+yyyy;
    }  
    ordinal_suffix_of = function(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }
    DINTtoDate = function (dint) {
        if(typeof dint==='undefined'){
            return "";
        }
        return this.pad(dint, 8).substr(6, 2) + '/' + this.pad(dint, 8).substr(4, 2) + '/' + this.pad(dint, 8).substr(0, 4);
    }
    DINTtoTime = function (dint) {
        if(typeof dint==='undefined'){
            return "";
        }
        return this.pad(dint, 6).substr(0, 2) + ':' + this.pad(dint, 6).substr(2, 2) + ':' + this.pad(dint, 6).substr(4, 2);
    }
    pad = function (num, places = 2) {
        var str = num.toString();
        while (str.length < places) {
            str = "0" + str;
        }

        return str;
    }

    roundToPlaces = function (num, places) {
        var div = "1";
        if (places < 0) { return 0; }
        if (places > 10) { return 0; }
        for (let i = 0; i < places; i++) {
            div = div + "0";
        }
        var base = Number(div);
        return Math.round(num * base) / base;
    }
    bit = function (num, place) {
        var mask = 1 << place;
        return (num & mask) != 0;
    }
}

export default Dashboard;