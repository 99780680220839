import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import md5 from 'md5';
import FaultList from '../Users/FaultList.js'

class UsersEdit extends Component {
    state = {
        loading: true,
        loadingMsg: "Loading users...",
        redirect: "",
        entities: [],
        data: null,
        message: "",
        messageState: "text-danger",
        buttonState: false,
        UserId: -1
    }
    onChange = (e) => {
        let data = this.state.data;
        //if(e.target.name in data){
            data[e.target.name]=e.target.value;
            var message = "";
            var messageState = "text-danger";
            var buttonState = false;
            if(this.state.data.NewPassword===this.state.data.ConfirmPassword && this.state.data.NewPassword.length > 0){
                message = "Passwords match!";
                messageState = "text-primary";
                buttonState = true;
            }
            if(this.state.data.NewPassword!==this.state.data.ConfirmPassword){
                message = "Passwords do not match"
            }
            if(this.state.data.NewPassword.length > 0 && this.state.data.NewPassword.length < 6){
                message = "Passwords must be 6 or more characters"
            }
            this.setState({ data: data, message: message, messageState: messageState, buttonState:buttonState});  
        //}
    };
    savePassword = () =>{
        const {databaseRequest}=this.props;
        const UserId = sessionStorage.getItem('user');
        let t=this;
        if(this.state.buttonState){
            this.setState({ message: "Password changed!", messageState: "text-success", buttonState:false});

            var req = {
                url: `/users/${UserId}`,
                method: "PUT"
            }
            databaseRequest(req.url,req.method, 
                JSON.stringify({Password: md5(this.state.data.NewPassword)})
            ).then((data)=>{
                setTimeout(function(){
                    t.setState({ message: "", messageState: "text-danger", NewPassword: "", ConfirmPassword: ""});
                }, 1000);
            });

            setTimeout(()=>{
                
            },1000);
        }
    }
    usersBtn = () =>{
        const {databaseRequest,setPage}=this.props;
        const UserId = sessionStorage.getItem('user');
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: "Loading users..."
        });
        if(this.state.data.Access>50){
            t.setState({
                loading: false,
                loadingMsg: "Loading users...",
                redirect: "/users",
            });
        }
        else{
            t.setState({
                loading: false,
                loadingMsg: "Loading users...",
                redirect: "./Home",
            });
        }
 
    }
    saveBtn = () =>{
        const {databaseRequest,setPage}=this.props;
        const UserId = sessionStorage.getItem('user');
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: `Saving ${this.state.data.Name}...`
        });

        var req = {
            url: `/users/${UserId}`,
            method: "PUT"
        }
        databaseRequest(req.url,req.method, 
            JSON.stringify(this.state.data)
        ).then((data)=>{
            setTimeout(function(){
                setPage(`${data.Name}`);
                data.NewPassword = "";
                data.ConfirmPassword = "";
                t.setState({
                    loading: false,
                    loadingMsg: "Loading users...",
                    redirect: "/account",
                    data: data
                });
            }, 250);
        });
    }
    data = (key) =>{
        let data = this.state.data;
        if(key in data){  
            return data[key];
        }
        else{
            return "";
        }
    }
    deleteBtn = () =>{
        const {databaseRequest}=this.props;
        const UserId = sessionStorage.getItem('user');
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: "Deleting "+this.data("Name")+"..."
        });
        databaseRequest(`/users/${UserId}`,"DELETE").then((data)=>{
            setTimeout(function(){
                t.setState({
                    loading: false,
                    loadingMsg: "Loading users...",
                    redirect: "/users"
                });
            }, 1000);
        });

    }
    componentDidMount(){
        const {databaseRequest,setPage}=this.props;
        const UserId = localStorage.getItem('user');
        let t = this;
        setPage(`Users`);
        databaseRequest(`/system/entities`,"GET").then(function(data){
            t.setState({
                entities: data.length>0?data:[]
            });
            databaseRequest(`/users/${UserId}`,"GET").then((data)=>{
                    setPage(`${data.Name}`);
                    t.setState({
                        loading: false,
                        data: data,
                        UserId: UserId
                    });
            });
        })
    }
    loading = () =>{
        return (
            <div className="w-100 mt-5 text-center">
                <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }
    page = () =>{
        const {props}=this;
        if(this.state.loading){
            return this.loading();
        }
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        return (
            <main role="main" className="container">
            <div className="p-5">
                <h2><i className="fe fe-user"></i> Users</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <h1 className="border-bottom">{this.data("Name")}</h1>
                    </div>
                    <div className="col p-0">
                        <button type="button" className="btn btn-info float-right" onClick={this.usersBtn}>Back to all Users</button>
                    </div>
                </div>
                <div className="row">
                    <form className="w-100">
                    <div className="form-row">
                        <div className="form-group col-md-3">
                        <label>Full Name</label>
                        <input type="text" className="form-control" placeholder="User Name" name="Name" value={this.data("Name")} onChange={this.onChange}/>
                        </div>
                        <div className="form-group col-md-2">
                        <label>Phone Name</label>
                        <input type="text" className="form-control" placeholder="Phone Number"  name="PhoneNumber" value={this.data("PhoneNumber")} onChange={this.onChange}/>
                        </div>                       
                        <div className="form-group col-md-3">
                        <label>Email</label>
                        <input type="text" className="form-control" placeholder="Email" required name="Email" value={this.data("Email")} onChange={this.onChange}/>
                        </div>

                        <div className="form-group col-md-3">
                        <label>Pushover Key</label>
                        <input type="text" className="form-control" placeholder="Pushover Key"  name="PushoverKey" value={this.data("PushoverKey")} onChange={this.onChange}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label>Entity</label>
                            <select className="form-control" name="EntityId" value={this.data("EntityId")} onChange={this.onChange}>
                                <option value="0">All Entities</option>
                                {this.state.entities.map((row, i) => {
                                    return (
                                        <option key={i} value={row.Id}>{row.Name}</option>
                                        )
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label>Password</label>
                            <input type="password" className="form-control" autoComplete="new-password" placeholder="New Password"  name="NewPassword" value={this.data("NewPassword")} onChange={this.onChange}/>
                        </div>
                        <div className="form-group col-md-3">
                            <label>Confirm Password</label>
                            <input type="password" className="form-control" autoComplete="new-password" placeholder="Confirm Password"  name="ConfirmPassword" value={this.data("ConfirmPassword")} onChange={this.onChange}/>
                        </div>
                        <div className="form-group col-md-3">
                            <label className="w-100">&nbsp;</label>
                            <button type="button" className="btn btn-warning" disabled={!this.state.buttonState} onClick={this.savePassword}>Change</button>
                            <p>
                            <small className={this.state.messageState}>
                            {this.state.message}
                            </small>
                            </p>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <button type="button" className="btn btn-success" onClick={this.saveBtn}>Save</button>
                            <button type="button" className="btn btn-danger ml-2" data-toggle="modal" data-target="#myModal" >Delete</button>
                            <Link to={`/history/${this.state.data.Guid}/${btoa(this.props.location.pathname)}`}>
                                <button type="button" className="btn btn-dark ml-3">History</button>
                            </Link>
                        </div>
                    </div>

                    <FaultList UserId={this.state.UserId}{...props}/>


                    </form>
                </div>
                <div className="modal" id="myModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Site</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete {this.data("Name")}?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.deleteBtn}>OK</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                        </div>
                        </div>
                    </div>
                </div>

            </div>
            </main>
        );

    }
    render() {
        return this.page();
    }
}

export default UsersEdit;