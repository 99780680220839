import React, { Component } from 'react';
import NavBar from '../NavBar/NavBar.js';


import Sites from './Systems/Sites.js';
import SitesEdit from './Systems/SitesEdit.js';

import Containers from './Systems/Containers.js';
import ContainersEdit from './Systems/ContainersEdit.js';

import FaultsDefault from './Systems/Faults/Default.js'
import FaultsSpecific from './Systems/Faults/Specific.js'
import FaultsSpecificEdit from './Systems/Faults/SpecificEdit.js'

import FaultsSubsUser from './Systems/Faults/SubscriptionsUser.js'
import FaultsSubsContainer from './Systems/Faults/SubscriptionsContainer.js'
import FaultsSubsList from './Systems/Faults/SubscriptionsList.js';


import IssuesAll from './Issues/IssuesAll.js'
import IssuesOutstanding from './Issues/IssuesOutstanding.js'
import IssuesResolved from './Issues/IssuesResolved.js'
import IssuesEdit from './Issues/IssuesEdit.js'


import Expenses from './Users/Expenses.js'
import ExpensesEdit from './Users/ExpensesEdit.js'


import Users from './Users/Users.js';
import UsersEdit from './Users/UsersEdit.js';
import UserAccount from './Users/Account.js';

import History from './History';

import Home from './Home/Home.js';


import {Route, Redirect} from "react-router-dom";

class Systems extends Component {
    timeMovedLast = 0;
    state = {
        navBarVisible: true,
        navBarVisibleInt: null,
        timeout: 3000
    }
    componentDidMount(){
        let t = this;
        clearTimeout(t.state.navBarVisibleInt);
        t.setState({ 
            navBarVisible: false,
            navBarVisibleInt: setInterval(()=>{
                var d = (new Date()).getTime();
                if((d-t.timeMovedLast) > t.state.timeout){
                    t.setState({navBarVisible:false})
                }
                
            },t.state.timeout)
         });
    }
    componentWillUnmount(){
        let t = this;
        clearTimeout(t.state.navBarVisibleInt);
    }
    _onMouseMove(e) {
        let t = this;
        this.timeMovedLast = (new Date()).getTime();
        if(!t.state.navBarVisible){
            t.setState({navBarVisible:true})
        }
    }
    render(){
        const {setPage}=this.props;
        const {props}=this;
        const access = localStorage.getItem("access");
        return (
            <div onMouseMove={this._onMouseMove.bind(this)} onTouchMove={this._onMouseMove.bind(this)} className="p-1 h-100">
                <NavBar visible={this.state.navBarVisible} {...props} setPage={setPage}/>
                
                {access>50?(<Route exact path="/systems/sites" render={()=>(<Sites {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>50?(<Route path="/systems/sites/:SiteId" render={({match})=>(<SitesEdit {...match} {...props} />)}/>):(<Redirect to="/home"/>)}

                {access>50?(<Route exact path="/systems/containers" render={()=>(<Containers {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>50?(<Route path="/systems/containers/:ContainerId" render={({match})=>(<ContainersEdit {...match} {...props} />)}/>):(<Redirect to="/home"/>)}

                {access>50?(<Route path="/faults/default" render={({})=>(<FaultsDefault {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>50?(<Route exact path="/faults/specific" render={({})=>(<FaultsSpecific {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>50?(<Route path="/faults/specific/:FaultId" render={({match})=>(<FaultsSpecificEdit {...match} {...props} />)}/>):(<Redirect to="/home"/>)}

                {access>10?(<Route exact path="/faults/subscriptions" render={({})=>(<FaultsSubsUser {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>10?(<Route exact path="/faults/subscriptions/:UserId" render={({match})=>(<FaultsSubsContainer {...match} {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>10?(<Route path="/faults/subscriptions/:UserId/:ContainerId" render={({match})=>(<FaultsSubsList {...match} {...props} />)}/>):(<Redirect to="/home"/>)}

                



                
                {access>10?(<Route exact path="/issues/active" render={()=>(<IssuesAll {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>10?(<Route exact path="/issues/outstanding" render={()=>(<IssuesOutstanding {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>10?(<Route exact path="/issues/resolved" render={()=>(<IssuesResolved {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>10?(<Route path="/issues/registry/:IssueId" render={({match})=>(<IssuesEdit {...match} {...props} />)}/>):(<Redirect to="/home"/>)}

                

                
                {access>0?(<Route exact path="/account" render={()=>(<UserAccount {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>50?(<Route exact path="/account/expenses" render={()=>(<Expenses {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>50?(<Route path="/account/expenses/:ExpenseId" render={({match})=>(<ExpensesEdit {...match} {...props} />)}/>):(<Redirect to="/home"/>)}

                
                {access>50?(<Route exact path="/users" render={()=>(<Users {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>50?(<Route path="/users/:UserId" render={({match})=>(<UsersEdit  {...match} {...props} />)}/>):(<Redirect to="/home"/>)}
                <Route path="/history/:Guid/:Redirect" render={({match})=>(<History  {...match} {...props} />)}/>
                <Route path="/home" render={({match})=>(<Home  visible={this.state.navBarVisible} {...match} {...props} />)}/>
                <Route exact path="/" render={({match})=>(<Home  visible={this.state.navBarVisible} {...match} {...props} />)}/>
            </div>
            
        )
    }
}
export default Systems;