import React, { Component } from 'react';
import { BrowserRouter as Router,Route,Switch,Redirect } from "react-router-dom";
import {isMobile} from 'react-device-detect';
import './App.css';
import Main from './Pages/Main'
import SignIn from './SignIn/SignIn.js';
import SignOut from './SignIn/SignOut.js';
import DashboardSummary from './Pages/Dashboards/Summary.js';
import Reporting from './Pages/Reporting.js';

class App extends Component {
  state = {
    serverActive: true,
    isLoggedIn: false,
    version: "2.13",
    googleLoaded: false,
    restServer:  "https://access.mpscinc.com",
    socketServer:  "https://stream.mpscinc.com" 
    //restServer:"http://127.0.0.1:20001",
    //socketServer:"http://127.0.0.1:20002"
  }
  googleLoaded = (state) =>{
    alert("Capsicum");
    this.setState({googleLoaded:state});
  }
  getGoogleState = () =>{
    return this.state.googleLoaded;
  }
  databaseRequest = (url,method,body=null,upload=false,token=localStorage.getItem('token')) =>{
    let t = this;
    return new Promise(function(resolve,reject){
      var opts;
      if(!upload){
        opts = {
          method: method,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-auth-token': token,
            'x-mobile': isMobile
          }
        };
      }
      else{
        opts = {
          method: method,
          headers: {
            'Accept': 'application/json',
            'x-auth-token': token
          }
        };
      }
      if(body!==null){
        opts.body=body;
      }
        fetch(`${t.state.restServer}/api${url}`,opts).then((response)=>{
        if(response.status===401){
          throw response.status;
        }
        return response.json();
      }).then((data) => {
        t.setState({serverActive:true});
        setTimeout(function(){
          resolve(data);
        }, 10);
        
      },(status)=>{
        reject(status);
        t.setState({isLoggedIn:false,serverActive:false});
      }).catch(function(e) {
        console.log(e);
        console.log("error");
      });
    });
  }
  onSignIn = (loginState) =>{
    this.setState({isLoggedIn:loginState});
  }
  setPage = (page) =>{
    document.title = page + " | MLink v"+this.state.version;
  }
  componentDidMount = () => {
    let t = this;
    const {databaseRequest,onSignIn,setPage}=this;
    


    setPage("Login");
        databaseRequest(`/login/${localStorage.getItem('token')}`,'GET').then((data)=>{     
            if(data.length===1){
                if(data[0].Login!==-1){
                    setTimeout(function(){
                        onSignIn(true);
                        localStorage.setItem('token', data[0].Token);
                        localStorage.setItem('user', data[0].UserId);
                        localStorage.setItem('entity', data[0].EntityId);
                        localStorage.setItem('access', data[0].Access);
                    }, 100);  
                }
                else
                {
                    onSignIn(false);
                    localStorage.clear();
                    t.setState({loading:false});
                }
            }
        }).catch((e)=>{
          console.log(e);
        })
      
    }
  render (){
    return (
          <Router>
            <Switch>
              {!this.state.serverActive && <Route exact path="/500" component={() => (<h3 className="p-3">Server not active...</h3>)} />}
              {this.state.serverActive && <Route exact path="/500" component={() => (<Redirect to="/login"/>)} />}
              {!this.state.serverActive && <Route component={() => (<Redirect to="/500"/>)} />}
              
              <Route path="/d/summary/:Token/:Species" render={({match})=>(<DashboardSummary setPage={this.setPage} {...match} socketServer={this.state.socketServer} databaseRequest={this.databaseRequest}/> )}/>
              
              {this.state.isLoggedIn && <Route path="/r/" render={(props)=>(<Reporting setPage={this.setPage} {...props} databaseRequest={this.databaseRequest}/> )}/>}
              {this.state.isLoggedIn && <Route exact path="/logout" component={()=>(<SignOut onSignIn={this.onSignIn} databaseRequest={this.databaseRequest} />)}/>}
              {this.state.isLoggedIn && <Route exact path="/login" component={() => (<Redirect to="/home"/>)} />}  
              {this.state.isLoggedIn && <Route render={(props) => (<Main setPage={this.setPage} {...props} socketServer={this.state.socketServer} databaseRequest={this.databaseRequest} googleLoaded={this.googleLoaded} getGoogleState={this.getGoogleState}/>)} />}
              <Route exact path="/logout" component={()=>(<Redirect to="/login"/>)}/>
              <Route exact path="/login" component={()=>(<SignIn setPage={this.setPage} onSignIn={this.onSignIn} databaseRequest={this.databaseRequest} signOut={false}/>)}/>
              <Route component={()=>(<SignIn setPage={this.setPage} onSignIn={this.onSignIn} databaseRequest={this.databaseRequest} signOut={false}/>)}/>
            </Switch>
          </Router>
    );
  }
}
export default App;

