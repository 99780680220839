import React, { Component } from 'react';
import {Link} from "react-router-dom";
import socketIOClient from "socket.io-client";

class Containers extends Component {
    state = {
        loading: true,
        search: "",
        containers: [],
        data: []
    }
    onSearch = (event) => {
        const {databaseRequest}=this.props;
        this.setState({loading: true});
        var search = event.target.value;
        databaseRequest(`/system/faults?Name:like=%${search}%`,"GET").then((data)=>{
                this.setState({
                    loading: false,
                    data: data.length>0?data:[]
                })
        });
    }
    containerName = (Id) =>{
        const {containers} = this.state;
        for(let i=0; i<containers.length; i++){
            if(Id==containers[i].Id){
                return containers[i].Name;
            }
        }
        return "Not allocated";
    }
    componentDidMount = () =>{
        const {databaseRequest,setPage}=this.props;
        let t=this;
        setPage(`Faults - Specific`);
        let containers = [];
        databaseRequest("/system/containers","GET").then((data)=>{ 
            containers = [...data];
            return databaseRequest("/system/faults","GET");
        }).then((data)=>{
            t.setState({
                loading: false,
                data: data.length>0?data:[],
                containers: containers
            })
        });
    }
    faultTag = (i)=>{
        var bit = i%32;
        var index = (i-bit)/32;
        return "MLFault["+index+"]."+bit
    }
    priority = (priority)=>{
        if(priority==-1){return (<i className="fe fe-message-circle text-success"  style={{padding: "1rem", fontSize: "2rem"}}></i>)}
        if(priority==0){return (<i className="fe fe-help-circle text-warning"  style={{padding: "1rem", fontSize: "2rem"}}></i>)}
        if(priority==1){return (<i className="fe fe-alert-circle text-danger"  style={{padding: "1rem", fontSize: "2rem"}}></i>)}
    }
    table = () => {    
        if(this.state.loading){
                return (
                    <main role="main" className="container">
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading specific faults...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    </main>
                )
            }
            else{
                return (
                    <table className="table table-striped">
                    <thead className="thead-dark">
                        <tr>
                        <th scope="col">Fault Code</th>
                        <th scope="col">Container</th>
                        <th scope="col">Message</th>
                        <th scope="col">Priority</th>
                        <th scope="col">Tag</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.data.map((row, i) => {
                        return (
                        <tr key={i}>
                            <th scope="row"><Link to={`/faults/specific/${row.Id}`}>[{row.FaultCode}]</Link></th>
                            <td>{this.containerName(row.ContainerId)}</td>
                            <td>{row.Message}</td>
                            <td>{this.priority(row.Priority)}</td>
                            <td>{this.faultTag(row.FaultCode)}</td>
                        </tr>)
                    })}
                    </tbody>
                    </table>
                )
            }
    }
    render(){
        return (
            <main role="main" className="container">
                <h2><i className="fe fe-bell"></i> Container Specific Alarms</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <form className="form-inline">
                            <input className="form-control mr-sm-2" type="search" onChange={this.onSearch} placeholder="Search" aria-label="Search"/>                      
                        </form>
                    </div>
                    <div className="col p-0">
                        <Link to="/faults/specific/-1"><button type="button" className="btn btn-success float-right">New Alarm</button></Link>
                    </div>
                </div>
                <div className="row">
                    {this.table()}
                </div>
            </main>

            
        )
    }
}
export default Containers;