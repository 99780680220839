import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";

class ContainersEdit extends Component {
    state = {
        loading: true,
        containers: [],
        loadingMsg: "Loading containers...",
        redirect: "",
        Name: "",
        SiteId: "",
        IPAddress: "",
        Slot: "",
        DataToCollect: "",
        Records: 0,
        RinsedOnly: false,
        Guid: ""
    }
    onChange = (e) => {
        let data = this.state.data;       
        if(e.target.name in data){            
            if(e.target.name=="FaultCode")
            {
                var v = Number(e.target.value);
                if(Number.isInteger(v)){
                    if(v >=0 && v <= 2000){
                        data[e.target.name]=v;
                    }
                }
            }
            else{
                data[e.target.name]=e.target.value;
            }
            this.setState({ data: data});  
        }
    };

    saveBtn = () =>{
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: "Saving "+this.data("Message")+"..."
        });

        var req = {
            url: `/system/faults/${params.FaultId}`,
            method: "PUT"
        }
        if(params.FaultId==="-1"){
            req.url="/system/faults";
            req.method="POST";
        }
        databaseRequest(req.url,req.method, 
            JSON.stringify(this.state.data)
        ).then((data)=>{
            setTimeout(function(){
                setPage(`${data.Message}`);
                t.setState({
                    loading: false,
                    loadingMsg: "Loading faults...",
                    data: data,
                    redirect: "/faults/specific"
                });
            }, 250);
        });
    }
    deleteBtn = () =>{
        const {databaseRequest,params}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: "Deleting "+this.data("Message")+"..."
        });
        databaseRequest(`/system/faults/${params.FaultId}`,"DELETE").then((data)=>{
            setTimeout(function(){
                t.setState({
                    loading: false,
                    loadingMsg: "Loading faults...",
                    redirect: "/faults/specific"
                });
            }, 1000);
        });

    }
    componentDidMount(){
        const {databaseRequest,params,setPage}=this.props;
        setPage(`Faults - Specific`);
        let containers = [];
        databaseRequest("/system/containers","GET").then((data)=>{ 
            containers = [...data];
        if(params.FaultId==="-1"){
            this.setState({
                data:{
                    Name: "--New Alarm--",
                    ContainerId: 0,
                    FaultCode: 0,
                    Message: "",
                    Priority: 0
                },
                loadingMsg: "Loading faults...",
                loading: false,
                containers: containers
            });
        }
        else{
            databaseRequest(`/system/faults/${params.FaultId}`,"GET").then((data)=>{
                    setPage(`[${data.FaultCode}] ${data.Message}`);
                    this.setState({
                        loading: false,
                        data: data,
                        containers: containers
                    });
            });
            
        }
    });

    }
    setPrioriy = (e,priority)=>{
        var {data} = this.state;
        data.Priority = priority;
        this.setState({ data: data});  
    }
    data = (key) =>{
        if(key in this.state.data){  
            return this.state.data[key];
        }
        else{
            return "";
        }
    }
    loading = () =>{
        return (
            <main role="main" className="container">
                <div className="w-100 mt-5 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </main>
        )
    }
    faultTag = (i)=>{
        var bit = i%32;
        var index = (i-bit)/32;
        return "MLFault["+index+"]."+bit
    }

    page = () =>{
        if(this.state.loading){
            return this.loading();
        }
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        return (
            <main role="main" className="container">
            <div className="p-5">
                <h2><i className="fe fe-bell"></i> Alarm</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <h1 className="border-bottom">{this.data("Message").length>0?this.data("Message"):"-- New Alarm --"}</h1>
                    </div>
                    <div className="col p-0">
                       <Link to="/faults/specific"><button type="button" className="btn btn-info float-right">Back to all alarms</button></Link>
                    </div>
                </div>
                <div className="row">
                    <form className="w-100">
                    <div className="form-row">
                       <div className="form-group col-md-1">
                            <label>Fault Code</label>
                            <input type="text" className="form-control" placeholder="Fault Code" name="FaultCode" value={this.data("FaultCode")} onChange={this.onChange}/>
                        </div>
                        <div className="form-group col-md-3">
                            <label>Message</label>
                            <input type="text" className="form-control" placeholder="Message" name="Message" value={this.data("Message")} onChange={this.onChange}/>
                        </div>
                        <div className="form-group col-md-3">
                            <label>Allocated Site</label>
                            <select className="form-control" name="ContainerId" value={this.data("ContainerId")} onChange={this.onChange}>
                                {this.state.containers.map((row, i) => {
                                    return (<option  key={i} value={row.Id}>{row.Name}</option>)
                                })}
                            </select>  
                        </div>
                        <div className="form-group col-md-2 text-center">
                            <label className="text-center">Priority</label>
                            <i onClick={e => this.setPrioriy(e,-1)} className={"fe fe-message-circle "+(this.state.data.Priority==-1?"text-success":"text-muted")} style={{padding: "1rem", fontSize: "2rem", cursor:"pointer"}}></i>
                            <i onClick={e => this.setPrioriy(e,0)} className={"fe fe-help-circle "   +(this.state.data.Priority==0?"text-warning":"text-muted")}  style={{padding: "1rem", fontSize: "2rem", cursor:"pointer"}}></i>
                            <i onClick={e => this.setPrioriy(e,1)} className={"fe fe-alert-circle "  +(this.state.data.Priority==1?"text-danger":"text-muted")}  style={{padding: "1rem", fontSize: "2rem", cursor:"pointer"}}></i>
                        </div>    
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label>Tag (read-only)</label>
                            <input type="text" disabled={true} read-only="true" className="form-control bg-muted" placeholder="Fault Code" name="FaultCode" value={this.faultTag(this.data("FaultCode"))}/>
                        </div>
                    </div>


                    
                    <div className="form-row">
                        <div className="form-group col-md-9">
                            <button type="button" className="btn btn-success" onClick={this.saveBtn}>Save</button>
                            <button type="button" className="btn btn-danger ml-3" data-toggle="modal" data-target="#myModal" >Delete</button>
                            
                            {this.props.params.FaultId!=="-1"?(
                            <Link to={`/history/${this.state.data.Guid}/${btoa(this.props.location.pathname)}`}>
                                <button type="button" className="btn btn-dark ml-3">History</button>
                            </Link>
                            ):(<div></div>)}
 
                        </div>
                    </div>
                    </form>
                </div>
                <div className="modal" id="myModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Fault</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete {this.state.Message}?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.deleteBtn}>OK</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </main>
        );

    }
    render() {
        return this.page();
    }
}

export default ContainersEdit;