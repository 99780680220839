import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";

class ContainersEdit extends Component {
    state = {
        loading: true,
        loadingMsg: "Loading issue...",
        redirect: "",
        preResolved: false,
        errorMessage: ""
    }
    onChange = (e) => {
        let data = this.state.data;
        if(e.target.name in data){
            data[e.target.name]=e.target.value;
            this.setState({ data: data});  
        }
    };
    resolvedChange = (e) =>{
        this.setState({ preResolved: !this.state.preResolved}); 
    };
    priorityChanged = (e) =>{
        const {databaseRequest}=this.props;
        let t=this;
        let data = this.state.data;
        if(e.target.name in data){
            data[e.target.name]=e.target.value;
            this.setState({ data: data});  
        }
        var req = {
            url: `/issues/${this.state.data.Id}/${this.state.data.Priority}`,
            method: "PUT"
        }
        databaseRequest(req.url,req.method, 
            JSON.stringify({})
        ).then((data)=>{
            setTimeout(function(){
                setTimeout(()=>{
                    t.updateComponent();
                }, 500);
            }, 250);
        });
    };
    updateIssue = () =>{
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: "Updating "+this.data("Title")+"..."
        });
        var req = {
            url: `/issues/${this.state.data.Id}`,
            method: "PUT"
        }
        var obj = {
            Id: this.state.data.Id,
            Description: this.state.data.updateDescription,
            State: this.state.data.updateState
        }
        databaseRequest(req.url,req.method, 
            JSON.stringify(obj)
        ).then((data)=>{
            setTimeout(function(){
                setTimeout(()=>{
                    t.updateComponent();
                }, 500);
            }, 250);
        });
    }
    saveBtn = () =>{
        const {databaseRequest,params,setPage}=this.props;
        let t = this;

        if(t.state.data.Title.length==0){
            t.setState({errorMessage: "The issue needs to be named correctly"});
            return;
        }

        this.setState({
            loading: true,
            loadingMsg: "Saving "+this.data("Title")+"..."
        });
        var req = {
            url: `/issues`,
            method: "POST"
        }
        databaseRequest(req.url,req.method, 
            JSON.stringify(this.state.data)
        ).then((data)=>{
            if(data.length==1){
                var newIssue = data[0];
                if(this.state.preResolved){
                    t.setState({
                        loading: true,
                        loadingMsg: "Updating "+t.data("Title")+"..."
                    });
                    var req = {
                        url: `/issues/${newIssue.Id}`,
                        method: "PUT"
                    }
                    var obj = {
                        Id: newIssue.Id,
                        Description: this.state.data.preResolvedText,
                        State: 4
                    }
                    databaseRequest(req.url,req.method, 
                        JSON.stringify(obj)
                    ).then((data)=>{
                        setTimeout(function(){
                            t.setState({
                                loading: false,
                                loadingMsg: "Loading issues...",
                                data: data,
                                redirect: "/issues/resolved"
                            });
                        }, 250);
                    });
                }
                else{
                    setTimeout(function(){
                        setPage(`${data.Name}`);
                        t.setState({
                            loading: false,
                            loadingMsg: "Loading issues...",
                            data: data,
                            redirect: "/issues/active"
                        });
                    }, 250);
                }
            }
        });
    }
    deleteBtn = () =>{
        const {databaseRequest,params}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: "Deleting "+this.data("Name")+"..."
        });
        databaseRequest(`/system/containers/${params.ContainerId}`,"DELETE").then((data)=>{
            setTimeout(function(){
                t.setState({
                    loading: false,
                    loadingMsg: "Loading containers...",
                    redirect: "/systems/containers"
                });
            }, 1000);
        });

    }
    componentDidMount(){
        this.updateComponent();
    }
    updateComponent = ()=>{
            const {databaseRequest,params,setPage}=this.props;
            setPage(`Issues`);
            var userEntity = Number(sessionStorage.getItem("entity")) || 0;
            let sites = [];
            let users = [];
            let entities = [];
            databaseRequest("/users","GET").then((data)=>{ 
                users = [...data];
                return databaseRequest("/system/sites","GET");
            }).then((data)=>{ 
                sites = [...data];
                return databaseRequest("/system/entities","GET");
            }).then((data)=>{ 
                entities = [...data];
                if(params.IssueId==="-1"){
                this.setState({
                    data:{
                        Title: "",
                        AllocatedSiteId: "",
                        EntityId: 0,
                        Description:"",
                        Priority: 0,
                        Category: "General",
                        preResolvedText: ""
                    },
                    loadingMsg: "Loading issue...",
                    loading: false,
                    sites: sites,
                    entities: entities,
                    users: users,
                    showEntityId: userEntity
                });
            }
            else{
                databaseRequest(`/issues/${params.IssueId}`,"GET").then((data)=>{
                        setPage(`${data.Title}`);
                        data.updateDescription = "";
                        data.updateState = 3;
                        this.setState({
                            loading: false,
                            data: data,
                            sites: sites,
                            users: users,
                            entities: entities,
                            showEntityId: userEntity
                        });
                });
                
            }
        });
    }
    entityName = (Id) =>{
        const {entities} = this.state;
        for(let i=0; i<entities.length; i++){
            if(Id==entities[i].Id){
                return entities[i].Name;
            }
        }
        return "All Entities";
    }
    siteName = (Id) =>{
        const {sites} = this.state;
        for(let i=0; i<sites.length; i++){
            if(Id==sites[i].Id){
                return sites[i].Name;
            }
        }
        return "Not site related";
    }
    username = (Id) =>{
        const {users} = this.state;
        for(let i=0; i<users.length; i++){
            if(Id==users[i].Id){
                return users[i].Name;
            }
        }
        return "-";
    }
    data = (key) =>{
        if(key in this.state.data){  
            return this.state.data[key];
        }
        else{
            return "";
        }
    }
    replaceLF = (str) =>{
        let newText = str.split('\\n').map((item, i) => {
            return <p key={i} className="mb-0">{item}</p>;
        });
        return newText;
    }
    loading = () =>{
        return (
            <main role="main" className="container"> 
                <div className="w-100 mt-5 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </main>
        )
        
    }
    timeAgo = (time) => {
        var d = new Date().getTime();
        var elapsed = d-time;
        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 60; //Two months
        var msPerYear = msPerDay * 365*2; //Two years
        if(elapsed < 10000){
            return "Now";
        }
        if (elapsed < msPerMinute) {
            return `${Math.round(elapsed/1000)} second${Math.round(elapsed/1000)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerHour) {
            return `${Math.round(elapsed/msPerMinute)} minute${Math.round(elapsed/msPerMinute)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerDay ) {
            return `${Math.round(elapsed/msPerHour)} hour${Math.round(elapsed/msPerHour)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerMonth) {
            return `${Math.round(elapsed/msPerDay)} day${Math.round(elapsed/msPerDay)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerYear) {
            return `${Math.round(elapsed/msPerMonth)} month${Math.round(elapsed/msPerMonth)>1?"s":""} ago`;   
        }

        else {
            return `${Math.round(elapsed/msPerYear)} year${Math.round(elapsed/msPerYear)>1?"s":""} ago`;   
        }
    }
    priority = (priority)=>{
        switch(priority){
            case 0:
                return "Low";
                break;
            case 1:
                return "Medium";
                break;
            case 2:
                return "High";
                break;
            case 3:
                return "Urgent";
                break;            
        }
        return "-";
    }
    stateColor = (state) =>{
        switch(state){
            case 0:
                return "#ddf4ff";
            case 1:
                return "inherit";
            case 2:
                return "#ffdddd";
            case 3:
                return "#fff8dd";          
            case 4:
                return "#ddffdd";
        }
        return "inherit";
    }
    stateName = (state) =>{
        switch(state){
            case 0:
                return "Created";
            case 1:
                return "Viewed";
            case 2:
                return "Outstanding";
            case 3:
                return "Underway";          
            case 4:
                return "Resolved";
        }
        return "Comment";
    }
    newIssue = () =>{
        return (
            <main role="main" className="container">
            <div className="p-5">
                <h2><i className="fe fe-alert-circle"></i> Issues</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <h1 className="border-bottom">{this.data("Title").length>0?this.data("Title"):"-- New Issue --"}</h1>
                    </div>
                    <div className="col p-0">
                       <Link to="/issues/active"><button type="button" className="btn btn-info float-right">Back to all Issues</button></Link>
                 </div>
                </div>
                <div className="row">
                    <form className="w-100">
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label>Name</label>
                            <input type="text" className="form-control" placeholder="Issue Name" autoComplete="off" name="Title" value={this.data("Title")} onChange={this.onChange}/>
                        </div>
                        <div className="form-group col-md-3">
                            <label>Category</label>
                            <select className="form-control" name="Category" value={this.data("Category")} onChange={this.onChange}>
                                <option>General</option>
                                <option>Control</option>
                                <option>Electrical</option>
                                <option>Mechanical</option>
                                <option>IT</option>
                                <option>Quality Assurance</option>
                                <option>Application</option>
                                <option>Customer Relations</option>
                            </select>  
                        </div>   
                        <div className="form-group col-md-2">
                            <label>Priority</label>
                            <select className="form-control" name="Priority" value={this.data("Priority")} onChange={this.onChange}>
                                <option value="0">Low</option>
                                <option value="1">Medium</option>
                                <option value="2">High</option>
                                <option value="3">Urgent</option>
                            </select>  
                        </div>       
                    </div>
                    <div className="form-row">
                        {this.state.showEntityId==0?(
                        <div className="form-group col-md-3">
                            <label>Entity</label>
                            <select className="form-control" name="EntityId" value={this.data("EntityId")} onChange={this.onChange}>
                                <option value="0">All Entities</option>
                                {this.state.entities.map((row, i) => {
                                    return (<option  key={i} value={row.Id}>{row.Name}</option>)
                                })}
                            </select>  
                        </div>
                        ):(<div></div>)}   
                        <div className="form-group col-md-3">
                            <label>Allocated Site</label>
                            <select className="form-control" name="AllocatedSiteId" value={this.data("AllocatedSiteId")} onChange={this.onChange}>
                                <option value="-1">Not site related</option>
                                {this.state.sites.map((row, i) => {
                                    return (<option  key={i} value={row.Id}>{row.Name}</option>)
                                })}
                            </select>  
                        </div> 
                    </div>       
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label>Description</label>
                            <textarea className="form-control" placeholder="Description of issue" name="Description" value={this.data("Description")} onChange={this.onChange}>

                            </textarea>
                        </div>
                    </div>                 
                    <div className="form-row">
                        <div className="form-check col-md-3">            
                            <input type="checkbox" className="form-check-input" name="Resolved" checked={this.state.preResolved} onChange={this.resolvedChange}/>
                            <label className="form-check-label font-weight-bold">Resolved</label>
                            <p>
                            <small className="text-muted">
                            Tick the checkbox if this issue is already resolved
                            </small>
                            </p>
                        </div>
                        <div className="form-check col-md-6"> 
                            {this.state.preResolved?(
                            <div>
                                <label>How was this issue resolved?</label>
                                <textarea className="form-control" placeholder="Description of fix" name="preResolvedText" value={this.data("preResolvedText")} onChange={this.onChange}>
                                </textarea>
                            </div>
                            ):(<div/>)}
                        </div>
                    </div>



                    
                    <div className="form-row">
                        <div className="form-group col-md-9">
                            <small className="text-danger">
                            {this.state.errorMessage}
                            </small>
                            <p/>
                            <button type="button" className="btn btn-success" onClick={this.saveBtn}>Save</button>

                        </div>
                    </div>


                    </form>
                </div>
                <div className="modal" id="myModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Container</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete {this.state.Name}?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.deleteBtn}>OK</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </main>
        );
    }
    exisitingIssue = () =>{
        const {data} = this.state;
        return (
            <main role="main" className="container">
            <div className="p-5">
                <h2><i className="fe fe-alert-circle"></i> Issues</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <h1 className="border-bottom">[{this.data("Category")}] {this.data("Title").length>0?this.data("Title"):"-- New Issue --"}</h1>
                    </div>
                    <div className="col p-0">
                       <Link to="/issues/active"><button type="button" className="btn btn-info float-right">Back to all Issues</button></Link>
                 </div>
                </div>
                <div className="row">
                    <form className="w-100">
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label>Raised</label>
                            <h2 className="font-italic">{new Date(this.data("CreatedDateTime")).toLocaleDateString()}</h2>
                        </div>
                        {this.state.showEntityId==0?(<div className="form-group col-md-3">
                            <label>Entity</label>
                            <h2 className="font-italic">{this.entityName(this.data("EntityId"))}</h2>
                        </div>):(<div></div>)}  
                        <div className="form-group col-md-3">
                            <label>Allocated Site</label>
                            <h2 className="font-italic">{this.siteName(this.data("AllocatedSiteId"))}</h2>
                        </div>  
                        <div className="form-group col-md-3">
                            <label>Priority</label>
                            {
                            (Number(this.state.data.ResolvedDateTime)>0?
                            (<h2 className="font-italic">{this.priority(this.data("Priority"))}</h2>):
                            (<select className="form-control" name="Priority" value={this.data("Priority")} onChange={this.priorityChanged}>
                                <option value="0">Low</option>
                                <option value="1">Medium</option>
                                <option value="2">High</option>
                                <option value="3">Urgent</option>
                            </select> ))}
                        </div>                 
                    </div>
                    {(Number(this.state.data.ResolvedDateTime)>0?(
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label>Raised By</label>
                            <h2 className="font-italic">{this.username(this.data("CreatedBy"))}</h2>
                        </div>
                        <div className="form-group col-md-3">
                            <label>Resolved By</label>
                            <h2 className="font-italic">{this.username(this.data("ResolvedBy"))}</h2>
                        </div>    
                        <div className="form-group col-md-3">
                            <label>Resolved On</label>
                            <h2 className="font-italic">{new Date(this.data("ResolvedDateTime")).toLocaleDateString()}</h2>
                        </div> 
                    </div>   ):(<div></div>))}

                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label>Description</label>
                            <p className="font-italic">
                            {this.replaceLF(this.data("Description"))}
                            </p>
                        </div>
                    </div>    
                    <div className="form-row">
                        <table className="table table-striped">
                        <thead className="thead-dark">
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Change</th>
                            <th scope="col">Who</th>
                            <th scope="col">When</th>
                            <th scope="col">State</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.details.slice(0).reverse().map((row, i) => {
                            return (
                            <tr key={i} style={{background: this.stateColor(row.State)}}>
                                <th scope="row" >{1+i}</th>
                                <td>{this.replaceLF(row.Description)}</td>
                                <td>{this.username(row.AllocatedTo)}</td>
                                <td>{this.timeAgo(row.DateTime)}</td>
                                <td>{this.stateName(row.State)}</td>
                            </tr>)
                        })}
                        </tbody>
                        </table>
                    </div>                    

                    
                    <div className="form-row">
                        <div className="form-group col-md-9">
                            {(Number(this.state.data.ResolvedDateTime)>0?(<div></div>):(<button type="button" className="btn btn-dark" data-toggle="modal" data-target="#myModal">Update</button>))}
                        </div>
                    </div>


                    </form>
                </div>
                <div className="modal" id="myModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title">{this.data("Title").length>0?this.data("Title"):"-- New Issue --"}</h1>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Enter updates regarding this issue.</p>
                            <div className="form-group col-md-9">
                                <label>State</label>
                                <select className="form-control" name="updateState" value={this.data("updateState")} onChange={this.onChange}>
                                    <option value="3">Underway</option>
                                    <option value="4">Resolved</option>
                                </select>  
                            </div>    
                            <div className="form-row">
                                <div className="form-group col-md-9">
                                    <label>Description</label>
                                    <textarea className="form-control" placeholder="Description" name="updateDescription" value={this.data("updateDescription")} onChange={this.onChange}>

                                    </textarea>
                                </div>
                            </div>    
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" data-dismiss="modal" onClick={this.updateIssue}>Save</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </main>
        ); 
    }


    page = () =>{
        const {params}=this.props;
        if(this.state.loading){
            return this.loading();
        }
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        if(params.IssueId==-1){
            return this.newIssue();
        }
        else{
            return this.exisitingIssue();
        }
        

    }
    render() {
        return this.page();
    }
}

export default ContainersEdit;