import React, { Component } from 'react';
import {Link} from "react-router-dom";

class History extends Component {
    state = {
        loading: true,
        data: []
    }
    componentDidMount = () =>{
        const {databaseRequest,setPage,params}=this.props;
        setPage(`Record history`);
        databaseRequest(`/history/${params.Guid}`,"GET").then((data)=>{
            this.setState({
                loading: false,
                data: data.length>0?data:[]
            })

        });
    }
    loading = () =>{
        return (
            <div className="w-100 mt-5 text-center">
                <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }
    page = () =>{
        return (
            <main role="main" className="container">
            <div className="p-5">
               
                <div className="row form-group">
                    <div className="col p-0">
                        <h2><i className="fe fe-clock"></i> Record history</h2>
                    </div>
                    <div className="col p-0">
                        <Link to={atob(this.props.params.Redirect)}><button type="button" className="btn btn-info float-right">Back</button></Link>
                    </div>
                </div>
                <div className="row">
                        {this.table()}
                </div>
            </div>
            </main>
        );
    }
    table = () => {    
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading history...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    <table className="table table-striped">
                    <thead className="thead-dark">
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Who</th>
                        <th scope="col">When</th>
                        <th scope="col">What</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.data.map((row, i) => {
                        return (
                        <tr key={i}>
                            <th scope="row" >{1+i}</th>
                            <td>{row.Name}</td>
                            <td>{(new Date(row.Datetime)).toLocaleString()}</td>
                            <td>{row.Comment}</td>
                        </tr>)
                    })}
                    </tbody>
                    </table>
                )
            }
    }
    render() {
        return this.page();
    }
}

export default History;