import React, { Component } from 'react';

class NavDropdown extends Component {
    getNavLinkClass = (path) => {
        return this.props.location.pathname.indexOf(path) >= 0 ? 'active' : '';
      }
    render (){
        return (
            <li className="nav-item dropdown">
                
                <a className={"nav-link dropdown-toggle "+this.getNavLinkClass(this.props.to)} href={this.props.to} style={{cursor: "pointer"}} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className={`fe fe-${this.props.icon}`}></i> {this.props.name}
                </a>

                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                {this.props.children}
                </div>
            </li>
        );
    }
}

export default NavDropdown;