import React, { Component, createRef } from 'react';
import { Link } from "react-router-dom";

class Report extends Component {
    state = {
        loading: true,
        data: [],
    }
    componentWillUnmount() {
        clearInterval(this.state.updateInt);
    }
    componentDidMount() {
        const { databaseRequest, params, setPage} = this.props;
        console.log(params);
        setPage("Report | Issues | Summary");
        let sites = [];
        let users = [];
        let entities = [];
        databaseRequest("/system/sites","GET").then((data)=>{ 
            sites = [...data];
            return databaseRequest("/users","GET");  
        }).then((data)=>{
            users = [...data];
            return databaseRequest("/system/entities","GET");
        }).then((data)=>{ 
            entities = [...data];
            if(typeof params.SiteId ==='undefined'){
                params.SiteId=-2;
            }
            return databaseRequest(`/issues?State:not=4${params.SiteId==-1?"":("&AllocatedSiteId="+params.SiteId)}`,"GET");
        }).then(async (data)=>{
            var newData = [];
            for(let i=0; i<data.length; i++){
                newData.push(await databaseRequest(`/issues/${data[i].Id}`,"GET"));
            }
            this.setState({
                loading: false,
                data: newData.length>0?newData:[],
                sites: sites,
                users: users,
                entities: entities
            })

        });

    }

    Report = ()=>{
        const { data } = this.state;
        document.body.style = 'background: #fff;';
        var Entities = [];
        var Sites = [];
        for(let i=0; i<data.length; i++){
            if(Entities.indexOf(data[i].EntityId) === -1) {
                Entities.push(data[i].EntityId);
              }
        }
        for(let i=0; i<data.length; i++){
            if(Sites.indexOf(Number(data[i].AllocatedSiteId)) === -1) {
                Sites.push(Number(data[i].AllocatedSiteId));
              }
        }
        Sites.sort(function(a, b){return b-a});
        return (
            <div>
            {Entities.map((row, i) => {
                return (
                    <div key={i}>
                        {this.Entity(this.entityName(row))}
                        {
                            Sites.map((row, j) => {

                                return (
                                <div key={j}>
                                    {this.Site(this.siteName(row),j)}
                                    {this.Issue(row)}
                                </div>
                                )
                            })
                        }
                    </div>
                )
            })
            }
            </div>
        )
    }


    Entity = (EntityName) =>{
        return (
            <div className="row">
                <div className="col p-1">
                    <h2>{EntityName}</h2>
                </div>
            </div>
        );
    }
    Site = (SiteName, i=0) =>{
        return (
            <div className={"row "+(i!==0?"mt-5":"")}>
                <div className="col pl-2">
                    <h1 className="font-weight-bold">{SiteName}</h1>
                </div>
            </div>
        );
    }
    Issue = (AllocatedSiteId) =>{
        const { data } = this.state;
        const Issues = [];
        for(let i=0; i<data.length; i++){
            if(Number(data[i].AllocatedSiteId)==AllocatedSiteId){
                Issues.push(data[i]);
            }
        }
        var Priorities = [];
        for(let i=0; i<Issues.length; i++){
            if(Priorities.indexOf((Issues[i].Priority)) === -1) {
                Priorities.push((Issues[i].Priority));
            }
        }
        Priorities.sort(function(a, b){return b-a});
        return (
            <div>
            {Priorities.map((priority, i) => {
                return (
                    <div key={i}>
                        {this.Priority(priority)}
                        {
                            Issues.map((row, j) => {
                                if(row.Priority==priority){
                                return (
                                    <div key={j}>
                                        {this.Details(row.Category,row.Title,row.CreatedBy,row.Description)}
                                        {this.LineItems(row.details)}
                                    </div>
                                )
                                }
                            })
                        }

                    </div>
                )
    
            })}
            </div>
            )
    }
    Priority = (Priority) =>{
        return (
            <div className="row">
                <div className="col pl-2">
                    <h3 className={"text-center font-weight-bold border-bottom "+(Priority>2?"text-danger":"text-warning")}>{this.priorityName(Priority)}</h3>
                </div>
            </div>
        );
    }
    Details = (Category,Title, CreatedBy,Description) =>{
        return (
            <div className="row">
                <div className="col pl-2">
                    <label>Title</label>
                    <p className="font-weight-bold">[{Category}] {Title}</p>
                </div>
                <div className="col">
                    <label className="text-right">Reported By</label>
                    <p className="font-weight-bold">{this.username(CreatedBy)}</p>
                </div>
                <div className="col">
                    <label>Description</label>
                        <p className="font-weight-bold">{Description}</p>
                </div>
            </div>
        );
    }
    LineItems = (details) =>{
        return (
            <div className="row">
                <table className="table table-striped">
                    <thead >
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Change</th>
                            <th scope="col">Who</th>
                            <th scope="col">When</th>
                            <th scope="col">State</th>
                        </tr>
                    </thead>
                    <tbody>
                    {details.slice(0).reverse().map((row, i) => {
                            return (
                            <tr key={i} style={{background: this.stateColor(row.State), WebkitPrintColorAdjust:'exact'}}>
                                <th scope="row" >{1+i}</th>
                                <td>{row.Description}</td>
                                <td>{this.username(row.AllocatedTo)}</td>
                                <td>{this.timeAgo(row.DateTime)}</td>
                                <td>{this.stateName(row.State)}</td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
    loading = () => {
        return (
            <div className="w-100 mt-5 text-center">
                <h1 className="h3 mb-3 font-weight-normal">Loading Report...</h1>
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }
    page = () => {
        if (this.state.loading) {
            return this.loading();
        }
        
        return (
            <div className="p-0">
                <div className="row">
                    <h1>Issue Summary Report</h1>
                </div>
                {this.Report()}
            </div>
        )
        
        return (             
            <div className="p-0">
                <div className="row">
                    <h1>Issue Summary Report</h1>
                </div>
                <div className="row">
                    <div className="col p-1">
                        <h2>Australia</h2>
                    </div>
                </div>
                <div className="row">
                        <div className="col pl-2">
                            <h1 className="font-weight-bold">Frederickton</h1>
                        </div>
                </div>
                <div className="row">
                        <div className="col pl-2">
                            <h3 className="text-center text-danger font-weight-bold border-bottom">Urgent</h3>
                        </div>
                </div>
                <div className="row">
                    <div className="col pl-2">
                        <label>Title</label>
                        <p className="font-weight-bold">[Electrical] PV34 not working</p>
                    </div>
                    <div className="col">
                        <label className="text-right">Allocated Site</label>
                        <p className="font-weight-bold">Tallangatta</p>
                    </div>
                    <div className="col">
                        <label>Description</label>
                        <p className="font-weight-bold">PV34 is not responding to calibration. Fergus has connected plant air to the actuator and masked out PV34 on the HMI</p>
                    </div>

                </div>

                <div className="row">
                        <table className="table table-striped">
                        <thead >
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Change</th>
                            <th scope="col">Who</th>
                            <th scope="col">When</th>
                            <th scope="col">State</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row" >1</th>
                            <td><Link to="#">Test</Link></td>
                            <td>T</td>
                            <td>E</td>
                            <td className="d-none d-md-table-cell">S</td>
                            <td className="d-none d-md-table-cell">T</td>
                        </tr>
                        <tr>
                            <th scope="row" >2</th>
                            <td><Link to="#">Test</Link></td>
                            <td>T</td>
                            <td>E</td>
                            <td className="d-none d-md-table-cell">S</td>
                            <td className="d-none d-md-table-cell">T</td>
                        </tr>
                        </tbody>
                        </table>
                    </div>   
            </div>
        );
    }
    render() {
        return this.page();
    }
    entityName = (Id) =>{
        const {entities} = this.state;
        for(let i=0; i<entities.length; i++){
            if(Id==entities[i].Id){
                return entities[i].Name;
            }
        }
        return "All Entities";
    }
    siteName = (Id) =>{
        const {sites} = this.state;
        for(let i=0; i<sites.length; i++){
            if(Id==sites[i].Id){
                return sites[i].Name;
            }
        }
        return "Not site related";
    }
    username = (Id) =>{
        const {users} = this.state;
        for(let i=0; i<users.length; i++){
            if(Id==users[i].Id){
                return users[i].Name;
            }
        }
        return "-";
    }
    priorityName = (priority)=>{
        switch(priority){
            case 0:
                return "Low";
                break;
            case 1:
                return "Medium";
                break;
            case 2:
                return "High";
                break;
            case 3:
                return "Urgent";
                break;            
        }
        return "-";
    }
    timeAgo = (time) => {
        var d = new Date().getTime();
        var elapsed = d-time;
        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 60; //Two months
        var msPerYear = msPerDay * 365*2; //Two years
        if(elapsed < 10000){
            return "Now";
        }
        if (elapsed < msPerMinute) {
            return `${Math.round(elapsed/1000)} second${Math.round(elapsed/1000)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerHour) {
            return `${Math.round(elapsed/msPerMinute)} minute${Math.round(elapsed/msPerMinute)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerDay ) {
            return `${Math.round(elapsed/msPerHour)} hour${Math.round(elapsed/msPerHour)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerMonth) {
            return `${Math.round(elapsed/msPerDay)} day${Math.round(elapsed/msPerDay)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerYear) {
            return `${Math.round(elapsed/msPerMonth)} month${Math.round(elapsed/msPerMonth)>1?"s":""} ago`;   
        }

        else {
            return `${Math.round(elapsed/msPerYear)} year${Math.round(elapsed/msPerYear)>1?"s":""} ago`;   
        }
    }
    stateColor = (state) =>{
        switch(state){
            case 0:
                return "#ddf4ff";
            case 1:
                return "inherit";
            case 2:
                return "#ffdddd";
            case 3:
                return "#fff8dd";          
            case 4:
                return "#ddffdd";
        }
        return "inherit";
    }
    stateName = (state) =>{
        switch(state){
            case 0:
                return "Created";
            case 1:
                return "Viewed";
            case 2:
                return "Outstanding";
            case 3:
                return "Underway";          
            case 4:
                return "Resolved";
        }
        return "Comment";
    }
}

export default Report;