import Highcharts from 'highcharts';
var gaugeOptions = {
    chart: {
        type: 'solidgauge',
        backgroundColor: '#f5f5f5'
    },

    title: null,
    credits: {
        enabled: false
    },
    exporting: {
        enabled: false
    },
    pane: {
        center: ['50%', '85%'],
        size: '140%',
        startAngle: -90,
        endAngle: 90,
        background: {
            backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc'
        }
    },

    tooltip: {
        enabled: false
    },
    yAxis: {
        stops: [
            [0.0, '#f0583d'],
            [0.1, '#f1d93e'],
            [0.9, '#62f23e']
        ],
        lineWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
            y: 15
        },
        labels: {
            y: -26
        }
    },

    plotOptions: {
        solidgauge: {
            dataLabels: {
                y: -45,
                borderWidth: 0,
                useHTML: true
            }
        }
    }
};
function options(){
    this.T1Level = Highcharts.merge(gaugeOptions, {
        yAxis: {
            min: 0,
            max: 100,
            title: {
                text: 'T1 Level'
            }
        },
        series: [{
            name: 'T1 Level',
            data: [0],
            dataLabels: {
                format: '<div style="text-align:center"><span style="font-size:25px;color:' +
                    ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') + '">{y:.1f}</span>' +
                    '<span style="font-size:12px;color:silver">%</span></div>'
            },
            tooltip: {
                valueSuffix: '%'
            }
        }]
    });
    this.T1Temp = Highcharts.merge(gaugeOptions, {
        yAxis: {
            min: 0,
            max: 30,
            title: {
                text: 'T1 Temp'
            },
            stops: [
                [0.0, '#62f23e'], 
                [0.6, '#f1d93e'],
                [0.9, '#f0583d']
            ],
        },

        series: [{
            name: 'T1 Temp',
            data: [0],
            dataLabels: {
                format: '<div style="text-align:center"><span style="font-size:25px;color:' +
                    ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') + '">{y:.1f}</span><br/>' +
                       '<span style="font-size:12px;color:silver">°C</span></div>'
            },
            tooltip: {
                valueSuffix: '°C'
            }
        }]

    });
    this.T1Cond = Highcharts.merge(gaugeOptions, {
        yAxis: {
            min: 0,
            max: 20,
            title: {
                text: 'T1 Conductivity'
            },
            stops: [
                [0.0, '#62f23e'], 
                [0.6, '#f1d93e'],
                [0.9, '#f0583d']
            ],
        },

        series: [{
            name: 'T1 Conductivity',
            data: [0],
            dataLabels: {
                format: '<div style="text-align:center"><span style="font-size:25px;color:' +
                    ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') + '">{y:.2f}</span><br/>' +
                       '<span style="font-size:12px;color:silver">mS</span></div>'
            },
            tooltip: {
                valueSuffix: 'mS'
            }
        }]
    });
    this.T2Level = Highcharts.merge(gaugeOptions, {
        yAxis: {
            min: 0,
            max: 100,
            title: {
                text: 'T2 Level'
            }
        },
        series: [{
            name: 'T2 Level',
            data: [0],
            dataLabels: {
                format: '<div style="text-align:center"><span style="font-size:25px;color:' +
                    ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') + '">{y:.1f}</span>' +
                    '<span style="font-size:12px;color:silver">%</span></div>'
            },
            tooltip: {
                valueSuffix: '%'
            }
        }]
    });
    this.T2Temp = Highcharts.merge(gaugeOptions, {
        yAxis: {
            min: 0,
            max: 30,
            title: {
                text: 'T2 Temp'
            },
            stops: [
                [0.0, '#62f23e'], 
                [0.6, '#f1d93e'],
                [0.9, '#f0583d']
            ],
        },

        series: [{
            name: 'T2 Temp',
            data: [0],
            dataLabels: {
                format: '<div style="text-align:center"><span style="font-size:25px;color:' +
                    ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') + '">{y:.1f}</span><br/>' +
                       '<span style="font-size:12px;color:silver">°C</span></div>'
            },
            tooltip: {
                valueSuffix: '°C'
            }
        }]

    });
    this.T2Cond = Highcharts.merge(gaugeOptions, {
        yAxis: {
            min: 0,
            max: 20,
            title: {
                text: 'T2 Conductivity'
            },
            stops: [
                [0.0, '#62f23e'], 
                [0.6, '#f1d93e'],
                [0.9, '#f0583d']
            ],
        },

        series: [{
            name: 'T2 Conductivity',
            data: [0],
            dataLabels: {
                format: '<div style="text-align:center"><span style="font-size:25px;color:' +
                    ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') + '">{y:.2f}</span><br/>' +
                       '<span style="font-size:12px;color:silver">mS</span></div>'
            },
            tooltip: {
                valueSuffix: 'mS'
            }
        }]
    });
    this.RinseTemp = Highcharts.merge(gaugeOptions, {
        yAxis: {
            min: 0,
            max: 30,
            title: {
                text: 'Rinse Temp'
            },
            stops: [
                [0.0, '#62f23e'], 
                [0.6, '#f1d93e'],
                [0.9, '#f0583d']
            ],
        },

        series: [{
            name: 'Rinse Temp',
            data: [0],
            dataLabels: {
                format: '<div style="text-align:center"><span style="font-size:25px;color:' +
                    ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') + '">{y:.1f}</span><br/>' +
                       '<span style="font-size:12px;color:silver">°C</span></div>'
            },
            tooltip: {
                valueSuffix: '°C'
            }
        }]

    });
}
 

export default new options();

