import React, { Component } from 'react';
import {Link} from "react-router-dom";
class UsersAll extends Component {
    state = {
        loading: true,
        search: "",
        data: [],
        entities: [],
        interval: null,
    }
    onSearch = (event) => {
        const {databaseRequest}=this.props;
        this.setState({loading: true});
        var search = event.target.value;
        databaseRequest(`/users?Name:like=%${search}%`,"GET").then((data)=>{
            if(typeof data.error !== 'undefined'){
                this.setState({
                    loading: false
                })
            }
            else{
                this.setState({
                    loading: false,
                    data: data
                })
            }

        });
    }
    entityName = (Id) =>{
        var Name = "All";
        for(let i=0; i<this.state.entities.length; i++){
            if(this.state.entities[i].Id===Id){
                Name = this.state.entities[i].Name;
            }
        }
        return Name;
    }
    componentDidMount = () =>{
        const {databaseRequest,setPage}=this.props;
        let t =this;
        setPage(`Users`);
        databaseRequest(`/system/entities`,"GET").then(function(data){
            t.setState({
                entities: data.length>0?data:[]
            });
            return databaseRequest("/users","GET");
        }).then((data)=>{
            if(typeof data.error !== 'undefined'){
                this.setState({
                    loading: false
                })
            }
            else{
                this.setState({
                    loading: false,
                    data: data
                })
            }
        });
        this.setState(
            {
                interval:   setInterval(function(){
                                let new_data = t.state.data;
                                databaseRequest("/login/active","GET").then((data)=>{
                                    for(let i=0; i<new_data.length; i++){
                                        new_data[i].Seconds="-";
                                        new_data[i].Mobile=false;
                                        for(let y=0; y<data.length; y++){
                                            if(new_data[i].Id===data[y].Id){
                                                new_data[i].Mobile=data[y].Mobile;
                                                new_data[i].Seconds=t.timeAgo(data[y].Since);
                                            }
                                        }
                                    }
                                    t.setState({
                                        data: new_data
                                    })
                                });
        }, 1000)
    });
    }
    componentWillUnmount = () =>{
        clearInterval(this.state.interval);
    }
    timeAgo = (elapsed) => {
        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;
        if (elapsed < msPerMinute) {
            return Math.round(elapsed/1000) + ' seconds ago';   
        }

        else if (elapsed < msPerHour) {
            return Math.round(elapsed/msPerMinute) + ' minutes ago';   
        }

        else if (elapsed < msPerDay ) {
            return Math.round(elapsed/msPerHour ) + ' hours ago';   
        }

        else if (elapsed < msPerMonth) {
            return Math.round(elapsed/msPerDay) + ' days ago';   
        }

        else if (elapsed < msPerYear) {
            return  Math.round(elapsed/msPerMonth) + ' months ago';   
        }

        else {
            return Math.round(elapsed/msPerYear ) + ' years ago';   
        }
    }
    table = () => {    
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading users...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    <table className="table table-striped">
                    <thead className="thead-dark">
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Site</th>
                        <th scope="col">E-mail</th>
                        <th scope="col">Contact Number</th>
                        <th scope="col">Last Active</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.data.map((row, i) => {
                        return (
                        <tr key={i}>
                            <th scope="row" ><Link to={`/users/${row.Id}`}>{1+i}</Link></th>
                            <td><Link to={`/users/${row.Id}`}>{row.Name} {row.Seconds==="-"?(<div></div>):(row.Mobile?(<i className="fe fe-smartphone"></i>):(<i className="fe fe-monitor"></i>))}</Link></td>
                            <td>{this.entityName(row.EntityId)}</td>
                            <td>{row.Email}</td>
                            <td>{row.PhoneNumber}</td>
                            <td>{row.Seconds}</td>
                        </tr>)
                    })}
                    </tbody>
                    </table>
                )
            }
    }

    render(){
        return (
            <main role="main" className="container">
                <h2><i className="fe fe-user-check"></i> All Users</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <form className="form-inline">
                            <input className="form-control mr-sm-2" type="search" onChange={this.onSearch} placeholder="Search" aria-label="Search"/>                      
                        </form>
                    </div>
                    <div className="col p-0">
                        <Link to="/users/-1"><button type="button" className="btn btn-success float-right">New User</button></Link>
                    </div>
                </div>
                <div className="row">
                    {this.table()}

                </div>
            </main>

            
        )
    }
}

export default UsersAll;