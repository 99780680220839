import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import md5 from 'md5';
import FaultList from '../Users/FaultList.js'

class UsersEdit extends Component {
    state = {
        loading: true,
        loadingMsg: "Loading users...",
        redirect: "",
        entities: [],
		sites: [],		  
        data: null,
        message: "",
        messageState: "text-danger",
        buttonState: false,
        siteState: false						
    }
    onChange = (e) => {
        let data = this.state.data;
        //if(e.target.name in data){
            data[e.target.name]=e.target.value;
            this.setState({ data: data});  
            var message = "";
            var messageState = "text-danger";
            var buttonState = false;
			var siteState = false;					  
            if(this.state.data.NewPassword===this.state.data.ConfirmPassword && this.state.data.NewPassword.length > 0){
                message = "Passwords match!";
                messageState = "text-primary";
                buttonState = true;
            }
            if(this.state.data.NewPassword!==this.state.data.ConfirmPassword){
                message = "Passwords do not match"
            }
            if(this.state.data.NewPassword.length > 0 && this.state.data.NewPassword.length < 6){
                message = "Passwords must be 6 or more characters"
            }  
            if(this.state.data.EntityId == 3){
                siteState = true;
            }
            else{
                this.state.data.SiteId = 0;
                siteState = false;            
            }
            this.setState({ data: data, message: message, messageState: messageState, buttonState:buttonState, siteState:siteState});
        //}
    };
    savePassword = () =>{
        const {databaseRequest,params}=this.props;
        let t=this;
        if(this.state.buttonState){
            this.setState({ message: "Password changed!", messageState: "text-success", buttonState:false});

            var req = {
                url: `/users/${params.UserId}`,
                method: "PUT"
            }
            databaseRequest(req.url,req.method, 
                JSON.stringify({Password: md5(this.state.data.NewPassword)})
            ).then((data)=>{
                setTimeout(function(){
                    t.setState({ message: "", messageState: "text-danger", NewPassword: "", ConfirmPassword: ""});
                }, 1000);
            });
        }
    }
    saveBtn = () =>{
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: `Saving ${this.state.data.Name}...`
        });
        var data = this.state.data;
        var req = {
            url: `/users/${params.UserId}`,
            method: "PUT"
        }
        if(params.UserId==="-1"){
            req.url="/users";
            req.method="POST";
            data.Password = md5(data.NewPassword);
        }
        databaseRequest(req.url,req.method, 
            JSON.stringify(data)
        ).then((data)=>{
            setTimeout(function(){
                setPage(`${data.Name}`);
                t.setState({
                    loading: false,
                    loadingMsg: "Loading users...",
                    redirect: "/users",
                    data: data
                });
            }, 250);
        });
    }
    data = (key) =>{
        let data = this.state.data;
        if(key in data){  
            return data[key];
        }
        else{
            return "";
        }
    }
    deleteBtn = () =>{
        const {databaseRequest,params}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: "Deleting "+this.data("Name")+"..."
        });
        databaseRequest(`/users/${params.UserId}`,"DELETE").then((data)=>{
            setTimeout(function(){
                t.setState({
                    loading: false,
                    loadingMsg: "Loading users...",
                    redirect: "/users"
                });
            }, 1000);
        });

    }
    componentDidMount(){
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        setPage(`Users`);
        databaseRequest(`/system/entities`,"GET").then(function(data){
            databaseRequest("/system/sites","GET").then((sites)=>{
                t.setState({
                    entities: data.length>0?data:[],
                    sites: sites.length>0?sites:[]
                });
            });
            if(params.UserId==="-1"){
                t.setState({
                    data:{
                        Name: "--New User--",
                        EntityId: 0,
                        NewPassword: "",
                        ConfirmPassword: ""
                    },
                    loadingMsg: "Loading users...",
                    loading: false
                });
            }
            else{
                databaseRequest(`/users/${params.UserId}`,"GET").then((data)=>{
                        setPage(`${data.Name}`);
                        data.NewPassword = "";
                        data.ConfirmPassword = "";
                        t.setState({
                            loading: false,
                            data: data
                        });
                        if(data.EntityId=='3'){
                            data.siteState = true; 
                        }
                        else{
                            data.siteState = false; 
                        }
                        t.setState({siteState:data.siteState});    
                });
            }
        })
    }
    loading = () =>{
        return (
            <div className="w-100 mt-5 text-center">
                <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }
    page = () =>{
        const {props} = this;
        if(this.state.loading){
            return this.loading();
        }
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        return (
            <main role="main" className="container">
            <div className="p-5">
                <h2><i className="fe fe-user"></i> Users</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <h1 className="border-bottom">{this.data("Name")}</h1>
                    </div>
                    <div className="col p-0">
                        <Link to="/users"><button type="button" className="btn btn-info float-right">Back to all Users</button></Link>
                    </div>
                </div>
                <div className="row">
                    <form className="w-100">
                    <div className="form-row">
                        <div className="form-group col-md-3">
                        <label>Full Name</label>
                        <input type="text" className="form-control" placeholder="User Name" name="Name" value={this.data("Name")} onChange={this.onChange}/>
                        </div>
                        <div className="form-group col-md-2">
                        <label>Phone Name</label>
                        <input type="text" className="form-control" placeholder="Phone Number"  name="PhoneNumber" value={this.data("PhoneNumber")} onChange={this.onChange}/>
                        </div>                       
                        <div className="form-group col-md-3">
                        <label>Email</label>
                        <input type="text" className="form-control" placeholder="Email" required name="Email" value={this.data("Email")} onChange={this.onChange}/>
                        </div>

                        <div className="form-group col-md-3">
                        <label>Pushover Key</label>
                        <input type="text" className="form-control" placeholder="Pushover Key"  name="PushoverKey" value={this.data("PushoverKey")} onChange={this.onChange}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label>Entity</label>
                            <select className="form-control" name="EntityId" value={this.data("EntityId")} onChange={this.onChange}>
                                <option value="0">All Entities</option>
                                {this.state.entities.map((row, i) => {
                                    return (
                                        <option key={i} value={row.Id}>{row.Name}</option>
                                        )
                                })}
                            </select>
                        </div>
                        <div className="form-group col-md-3">
                            <label>Site</label>
                            <select className="form-control" name="SiteId" disabled={!this.state.siteState} value={this.data("SiteId")} onChange={this.onChange}>
                                <option value="0">All Sites</option>
                                {this.state.sites.map((row, i) => {
                                    return (
                                        <option key={i} value={row.Id}>{row.Name}</option>
                                        )
                                })}
                            </select>
                        </div>     
                        <div className="form-group col-md-3">
                            <label>MLink Access</label>
                            <select className="form-control" name="Access" value={this.data("Access")} onChange={this.onChange}>
                                <option value="0">No Access</option>
                                <option value="10">View-Only</option>
                                <option value="15">Service</option>
                                <option value="20">Technical</option>
                                <option value="100">Administrator</option>
                            </select>
                        </div>
 

                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label>Password</label>
                            <input type="password" className="form-control" autoComplete="new-password" placeholder="New Password"  name="NewPassword" value={this.data("NewPassword")} onChange={this.onChange}/>
                        </div>
                        <div className="form-group col-md-3">
                            <label>Confirm Password</label>
                            <input type="password" className="form-control" autoComplete="new-password" placeholder="Confirm Password"  name="ConfirmPassword" value={this.data("ConfirmPassword")} onChange={this.onChange}/>
                        </div>
                        <div className="form-group col-md-3">
                            <label className="w-100">&nbsp;</label>
                            {this.props.params.UserId!=="-1"?(
                            <button type="button" className="btn btn-warning" disabled={!this.state.buttonState} onClick={this.savePassword}>Change</button>
                            ):(<div></div>)}
                            <p>
                            <small className={this.state.messageState}>
                            {this.state.message}
                            </small>
                            </p>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            {this.props.params.UserId!=="-1"?(
                            <button type="button" className="btn btn-success" onClick={this.saveBtn}>Save</button>
                            ):(
                            <button type="button" className="btn btn-success" disabled={!this.state.buttonState} onClick={this.saveBtn}>Create</button>
                            )}
                            {this.props.params.UserId!=="-1"?(
                            <button type="button" className="btn btn-danger ml-2" data-toggle="modal" data-target="#myModal" >Delete</button>
                            ):(<div/>)}
                            {this.props.params.UserId!=="-1"?(
                            <Link to={`/history/${this.state.data.Guid}/${btoa(this.props.location.pathname)}`}>
                                <button type="button" className="btn btn-dark ml-3">History</button>
                            </Link>
                            ):(<div></div>)}
                        </div>
                    </div>

                    <FaultList UserId={this.props.params.UserId}{...props}/>
                    </form>
                </div>
                <div className="modal" id="myModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete User</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete {this.data("Name")}?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.deleteBtn}>OK</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </main>
        );

    }
    render() {
        return this.page();
    }
}

export default UsersEdit;