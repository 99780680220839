import React, { Component } from 'react';
import {Link} from "react-router-dom";


class Sites extends Component {
    state = {
        loading: true,
        search: "",
        data: []
    }
    onSearch = (event) => {
        const {databaseRequest}=this.props;
        this.setState({loading: true});
        var search = event.target.value;
        databaseRequest(`/system/sites?Name:like=%${search}%`,"GET").then((data)=>{
                this.setState({
                    loading: false,
                    data: data.length>0?data:[]
                })
        });
    }
    entityName = (Id) =>{
        const {entities} = this.state;
        for(let i=0; i<entities.length; i++){
            if(Id==entities[i].Id){
                return entities[i].Name;
            }
        }
        return "Master Site";
    }
    componentDidMount = () =>{
        const {databaseRequest,setPage}=this.props;
        setPage(`Sites`);
        databaseRequest("/system/entities","GET").then((entities)=>{
            databaseRequest("/system/sites","GET").then((data)=>{
                this.setState({
                    loading: false,
                    data: data.length>0?data:[],
                    entities: entities.length>0?entities:[]
                })

            });
        });
    }
    table = () => {    
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading sites...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    <table className="table table-striped">
                    <thead className="thead-dark">
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Company</th>
                        <th scope="col">Entity</th>
                        <th scope="col">Address</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.data.map((row, i) => {
                        return (
                        <tr key={i}>
                            <th scope="row" >{1+i}</th>
                            <td><Link to={`/systems/sites/${row.Id}`}>{row.Name}</Link></td>
                            <td>{row.CompanyName}</td>
                            <td>{this.entityName(row.EntityId)}</td>
                            <td>{row.Address}</td>
                        </tr>)
                    })}
                    </tbody>
                    </table>
                )
            }
    }

    render(){
        return (
            <main role="main" className="container">
                <h2><i className="fe fe-compass"></i> Sites</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <form className="form-inline">
                            <input className="form-control mr-sm-2" type="search" onChange={this.onSearch} placeholder="Search" aria-label="Search"/>                      
                        </form>
                    </div>
                    <div className="col p-0">
                        <Link to="/systems/sites/-1"><button type="button" className="btn btn-success float-right">New Site</button></Link>
                    </div>
                </div>
                <div className="row">
                    {this.table()}

                </div>
            </main>

            
        )
    }
}
export default Sites;