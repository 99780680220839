import React, { Component, createRef } from 'react';
import {Link} from "react-router-dom";
import socketIOClient from "socket.io-client";
import RinsesAndTemperatures from './RinsesAndTemperatures.js'
import RunningTotal from './RunningTotal.js';
import Issues from './Issues.js'
import Map from './Map.js'
import Revenue from './Revenue.js'
import RevenuePie from './RevenuePie.js'
import MissedRevenue from './MissedRevenue.js'


class Home extends Component {
    TAGS = [];
    data = [];

    state = {
        loading: false,
        sites: [],
        containers: [],
        updateInt: null,
        RTWidgets: [],
        updates: 0,
        data: [],
        revenue: 0,
        missedRevenue: 0
    }
    componentWillUnmount(){
        clearInterval(this.state.updateInt);
    }
    componentDidMount = async ()=> {
        const {socketServer, setPage, databaseRequest}=this.props;
        setPage("Dashboard");
        var containers = await databaseRequest("/system/containers","GET");
        var sites = await databaseRequest("/system/sites","GET");

        this.setState({
            loading: false,
            containers: containers.length>0?containers:[],
            sites: sites.length>0?sites:[]
        });
        let t = this;
        const socket = socketIOClient(socketServer);
        
        var RTWidgets = [];
        for(let i=0; i<containers.length; i++){
            //If MLLive AOI v2
            if(this.bit(containers[i].DataToCollect,6)){
                for(let j=0; j<32; j++){
                    t.TAGS.push(`[${containers[i].Name}]MLFlowRates[${j}]`);
                }
            }
            

            if(this.bit(containers[i].DataToCollect,4)){
                t.TAGS.push(`[${containers[i].Name}]MLLiveBeef.HosePurged`);
                t.TAGS.push(`[${containers[i].Name}]MLLiveBeef.Weighed`);
                t.TAGS.push(`[${containers[i].Name}]MLLiveBeef.Rinsed`);
                t.TAGS.push(`[${containers[i].Name}]MLLiveBeef.RinseboxTemp`);
                t.TAGS.push(`[${containers[i].Name}]MLLiveBeef.State`);
                for(let j=0; j<sites.length; j++){
                    if(containers[i].SiteId===sites[j].Id){
                        RTWidgets.push({
                            Token: containers[i].Token,
                            Site: sites[j].Name,
                            Container: containers[i].Name,
                            Species: "Beef"
                        })
                    }
                }
                
            }
            if(this.bit(containers[i].DataToCollect,5)){
                t.TAGS.push(`[${containers[i].Name}]MLLiveLamb.HosePurged`);
                t.TAGS.push(`[${containers[i].Name}]MLLiveLamb.Weighed`);
                t.TAGS.push(`[${containers[i].Name}]MLLiveLamb.Rinsed`);
                t.TAGS.push(`[${containers[i].Name}]MLLiveLamb.RinseboxTemp`);
                t.TAGS.push(`[${containers[i].Name}]MLLiveLamb.State`);
                for(let j=0; j<sites.length; j++){
                    if(containers[i].SiteId===sites[j].Id){
                        RTWidgets.push({
                            Token: containers[i].Token,
                            Site: sites[j].Name,
                            Container: containers[i].Name,
                            Species: "Lamb"
                        })
                    }
                }
            }
        }
        
        var request = function(){
            socket.emit("request", t.TAGS, function (values) { 
                t.setState({
                    data: values,
                    updates: t.state.updates+1
                });
            });
            t.calculateRevenue();
        };
        t.setState({
            updateInt: setInterval(request,1000),
            RTWidgets: RTWidgets.reverse()
        });
        request();
        
        
        
    }
    bit = (num, place) =>{
        var mask = 1 << place; 
        return(num & mask) != 0; 
    };
    age = (values) =>{
        var maxAge = 10000000000;
        for (let i = 0; i < values.length; i++) {
            if (maxAge > values[i].Age) {
                maxAge = values[i].Age;
            }
        }
        return maxAge;
    }
    getRevenue = ()=>{
        let t = this;
        var total = 0;
        for(let i=0; i<t.state.containers.length; i++){
            for(let j=0; j<t.state.data.length;j++){
                
            }
        }
        return total;
    }
    calculateRevenue = ()=>{
        let t = this;
        var revenue = 0;
        var total = 0;
        for(let i=0; i<t.state.containers.length; i++){
            for(let j=0; j<t.state.data.length;j++){
                if(`[${t.state.containers[i].Name}]MLLiveBeef.Weighed` === t.state.data[j].Tag){
                    total += (t.state.containers[i].BeefPrice*t.state.data[j].Value)
                }
                if(`[${t.state.containers[i].Name}]MLLiveLamb.Weighed` === t.state.data[j].Tag){
                    total += (t.state.containers[i].LambPrice*t.state.data[j].Value)
                }
                if(`[${t.state.containers[i].Name}]MLLivePork.Weighed` === t.state.data[j].Tag){
                    total += (t.state.containers[i].PorkPrice*t.state.data[j].Value)
                }
                if(`[${t.state.containers[i].Name}]MLLiveBeef.Rinsed` === t.state.data[j].Tag){
                    revenue += (t.state.containers[i].BeefPrice*t.state.data[j].Value)
                }
                if(`[${t.state.containers[i].Name}]MLLiveLamb.Rinsed` === t.state.data[j].Tag){
                    revenue += (t.state.containers[i].LambPrice*t.state.data[j].Value)
                }
                if(`[${t.state.containers[i].Name}]MLLivePork.Rinsed` === t.state.data[j].Tag){
                    revenue += (t.state.containers[i].PorkPrice*t.state.data[j].Value)
                }
            }
        }
        t.setState({
            revenue: revenue,
            missedRevenue: total-revenue
        })
    }
    getRevenueDistribution = ()=>{
        let t = this;
        var data = [];
        for(let i=0; i<t.state.sites.length; i++){
            var rinsed = 100;
            var total = 100;
            for(let j=0; j<t.state.containers.length; j++){
                if(t.state.containers[j].SiteId===t.state.sites[i].Id){
                    for(let k=0; k<t.state.data.length;k++){
                        if(`[${t.state.containers[j].Name}]MLLiveBeef.Rinsed` === t.state.data[k].Tag){
                            total += (t.state.containers[j].BeefPrice*t.state.data[k].Value)
                            rinsed += t.state.data[k].Value;
                        }
                        if(`[${t.state.containers[j].Name}]MLLiveLamb.Rinsed` === t.state.data[k].Tag){
                            total += (t.state.containers[j].LambPrice*t.state.data[k].Value)
                            rinsed += t.state.data[k].Value;
                        }
                        if(`[${t.state.containers[j].Name}]MLLivePork.Rinsed` === t.state.data[k].Tag){
                            total += (t.state.containers[j].PorkPrice*t.state.data[k].Value)
                            rinsed += t.state.data[k].Value;
                        }

                    }
                }


            }   
            data.push({
                Name: t.state.sites[i].Name,
                Rinsed: rinsed,
                Revenue: total
            });         
        }
        return data;
    }
    getRTValue = (Site,Container,Species)=>{
        let t = this;
        var obj = {Total: 0, Rinsed: 0, Temp: 0, Age: 10000000000, Flowrates: [],InProduction:false,InCIP:false,Faulted:false,CIPComplete:false, HosePurged: 0};
        for( let i=0; i<32; i++){
            obj.Flowrates[i]=0;
        }
        for(let i=0; i<t.state.data.length;i++){
            for(let j=0; j<32; j++){        
                if(`[${Container}]MLFlowRates[${j}]` === t.state.data[i].Tag){
                    obj.Flowrates[j]=t.state.data[i].Value;
                }        
            }
            if(`[${Container}]MLLive${Species}.HosePurged` === t.state.data[i].Tag){
                obj.HosePurged = t.state.data[i].Value;
                obj.Age = t.state.data[i].Age;
            }
            if(`[${Container}]MLLive${Species}.RinseboxTemp` === t.state.data[i].Tag){
                obj.Temp = t.state.data[i].Value;
                obj.Age = t.state.data[i].Age;
            }
            if(`[${Container}]MLLive${Species}.Weighed` === t.state.data[i].Tag){
                obj.Total = t.state.data[i].Value;
                obj.Age = t.state.data[i].Age;
            }
            if(`[${Container}]MLLive${Species}.Rinsed` === t.state.data[i].Tag){
                obj.Rinsed = t.state.data[i].Value;
                obj.Age = t.state.data[i].Age;
            }
            if(`[${Container}]MLLive${Species}.State` === t.state.data[i].Tag){
                obj.InProduction = t.bit(t.state.data[i].Value,0);
                obj.InCIP = t.bit(t.state.data[i].Value,1);
                obj.Faulted = t.bit(t.state.data[i].Value,2);
                obj.CIPComplete = t.bit(t.state.data[i].Value,3);
                obj.Age = t.state.data[i].Age;
            }
        }
        
        return obj
    }

    loading = () =>{
        return (
            <div className="w-100 mt-5 text-center">
                <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }
    page = () =>{
        const {visible} = this.props;
        if(this.state.loading){
            return this.loading();
        }
        return (
            <main role="main" className="container-fluid d-flex flex-column flex-grow h-100">
                {visible?(<div className="row mt-5 pt-5"></div>):(<div className="row mt-1 pt-1"></div>)}
                <div className="row" >            
                    {this.state.RTWidgets.map((row, i) => {
                        return (
                            <div className="float-left ml-2 mr-2 " key={i}>
                                <RinsesAndTemperatures Container={row.Container} Site={row.Site} Species={row.Species} Token={row.Token} values={this.getRTValue(row.Site,row.Container,row.Species)}/>
                            </div>)
                    })}
                </div>


                <div className="row mt-2 pt-2"></div>
            </main>
        );
    }
    render() {
        return this.page();
    }
}

export default Home;