import React, { Component, createRef } from 'react';
import {Link} from "react-router-dom";
import socketIOClient from "socket.io-client";




class Home extends Component {
    state = {
        loading: true,
        markers: [],
        data: []
    }
    googleMapRef = React.createRef()

    componentDidMount() {
        const {socketServer, setPage}=this.props;
        //const socket = socketIOClient("https://stream.mpscinc.com");
        const socket = socketIOClient(socketServer);
        setPage("Home");
            this.setState(
                {
                    interval:setInterval(function(){
                socket.emit("online",function(value){
                    console.log(value);
                });
            }, 5000)
        });

        const googleScript = document.createElement('script');
        googleScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD5RdsS_RxpLxIuZ2W1iFfzi6CDZMs-_eg&libraries=places`
        window.document.body.appendChild(googleScript)
        
        googleScript.addEventListener('load', ()=>{    
            this.makeMap();
        })
        this.setState({loading: false});
    }
    updateConnections = (connections)=>{
        this.clearMarkers();
        for(let i =0; i<connections.length; i++){
            console.log(connections[i]);
            //this.addMarker(data[i].Latitude,data[i].Longitude,"AU102",data[i].Name, "red");
        }
        
    }
    makeMap = () => {
        const {databaseRequest}=this.props;
        /*const socket = socketIOClient(socketServer);
        let t=this;
        socket.on('connect', ()=>{
            setTimeout(()=>{
                socket.emit("containers-online",function(data){
                    t.updateConnections(data);
                });
                setInterval(function(){
                    socket.emit("containers-online",function(data){
                        t.updateConnections(data);
                    });
                }, 5000);
            }, 1000);

        })*/


        let sites = [];
        databaseRequest("/system/sites","GET").then((data)=>{ 
            for(let i=0; i<data.length; i++){
                if(Math.abs(data[i].Latitude) > 0 || Math.abs(data[i].Longitude) > 0){
                    //this.addMarker(data[i].Latitude,data[i].Longitude,data[i].Name,data[i].Name, "red");
                    switch(i%4){
                        case 0:
                        this.createMarker(data[i].Latitude,data[i].Longitude,"AU102",data[i].Name, "blue")
                        break;
                        case 1:
                        this.createMarker(data[i].Latitude,data[i].Longitude,"AU102",data[i].Name, "green")
                        break;
                        case 2:
                        this.createMarker(data[i].Latitude,data[i].Longitude,"AU102",data[i].Name, "yellow")
                        break;
                        case 3:
                        this.createMarker(data[i].Latitude,data[i].Longitude,"AU102",data[i].Name, "red")
                        break;
                    }
                    
                }
            }
        })
        this.googleMap = this.createGoogleMap();
        
        
    }
    createGoogleMap = () => {
        var styles=[{featureType:"water",stylers:[{color:"#ffffff"}]},{featureType:"landscape",stylers:[{color:"#acd4fa"}]},{featureType:"road",elementType:"geometry",stylers:[{color:"#808080"}]},{featureType:"poi",stylers:[{weight:.1},{color:"#72abe0"}]},{featureType:"road",elementType:"labels.icon",stylers:[{visibility:"off"}]},{},{}];
        return new window.google.maps.Map(this.googleMapRef.current, {
        zoom: 2,
        styles: styles,
        center: {
            lat: 0,
            lng: 0,
        },
        disableDefaultUI: true,
        })
    }
    addMarker = (lat, lng, container, site, color)=>{
        var markers = this.state.markers;
        var marker = new window.google.maps.Marker({
            icon: '/img/'+color+'.png',    
            position: { lat: lat, lng: lng },
            title: container+" - "+site
        });
        markers.push({ContainerName: container, m:marker});
        this.setState(markers);
    }
    setMapOnAll = (map) => {
        var markers = this.state.markers;
        for (var i = 0; i < markers.length; i++) {
          markers[i].m.setMap(map);
        }
        this.setState(markers);
    }
    clearMarkers = ()=>{
        this.setMapOnAll(null);
    }
    showMarkers = () =>{
        this.setMapOnAll(this.googleMap);
    }
    createMarker = (lat, lng, container, site, color) =>
        new window.google.maps.Marker({
        icon: '/img/'+color+'.png',    
        position: { lat: lat, lng: lng },
        title: container+" - "+site,
        map: this.googleMap,
     });

    loading = () =>{
        return (
            <div className="w-100 mt-5 text-center">
                <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }
    page = () =>{
        if(this.state.loading){
            return this.loading();
        }
        return (
            <div className="bg-white shadow rounded"
            id="google-map"
            ref={this.googleMapRef}
        />
        );
    }
    render() {
        return this.page();
    }
}

export default Home;