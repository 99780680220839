import React, { Component } from 'react';
import {Link} from "react-router-dom";
class UsersAll extends Component {
    state = {
        loading: true,
        users: []
    }
    componentDidMount = async () =>{
        const {databaseRequest,setPage}=this.props;
        setPage(`Subscriptions - System`);
        var users = await databaseRequest("/users","GET");
        this.setState({
            loading: false,
            users: users.length>0?users:[]
        });
    }
    tagCloud = () =>{
        if(this.state.loading){
            return (
                <div className="w-100 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">Loading users...</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )
        }
        else{
            return (
            <div>
                {this.state.users.map((row, i) => {
                        return (
                            <Link key={i} to={`/faults/subscriptions/${row.Id}`}><div className="tag">{row.Name}</div></Link>
                        )
                })
            }  
            </div>
            );
        }
    }
    render(){
        return (
            <main role="main" className="container">
                <h2><i className="fe fe-bell"></i> User Selection <i className="fe fe-user-check"></i></h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <p>Assign user to subscription.</p>
                    </div>
                </div>
                <div className="row">
                    {this.tagCloud()}
                </div>
            </main>

            
        )
    }
}

export default UsersAll;