import React, { Component, createRef } from 'react';
import { Link } from "react-router-dom";

class Dashboard extends Component {
    state = {
        loading: true,
        data: [],
        updateInt: null,
        revenue: 0
    }

    page = () => {
        const {revenue}=this.props;
        return (
            <div className="h-100 shadow bg-white rounded p-1 mb-3">
                <div className="card-body p-2">
                    <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1">Revenue (Daily)</div>
                            <div className="h1 mb-0 font-weight-bold text-gray-800">${this.numberWithCommas(revenue)}</div>
                        </div>
                        <div className="col-auto">
                            <i className="fe fe-dollar-sign h1"></i>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    render() {
        return (
            <div>{this.page()}</div>
        )
    }
    numberWithCommas = (x)=>{
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

}

export default Dashboard;