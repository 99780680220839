import React, { Component } from 'react';
import {Redirect } from "react-router-dom";
class SignOut extends Component {
    state = {
        loading: true,
        timeout: null
    }
    componentDidMount= (event) =>{
        let t = this;
        const {databaseRequest,onSignIn}=this.props;
        databaseRequest(`/login/${localStorage.getItem('token')}`,'DELETE').then((data)=>{ 
            t.timeout = setTimeout(function(){ 
                onSignIn(false);
                localStorage.clear();
            }, 1000);
        });

    }
    componentWillUnmount = () =>{
        let t = this;
        clearTimeout(t.timeout);
    }
    render (){
        let page;  
        if(this.state.loading){
            page = (
                <div style={{padding:"1rem"}}>Please wait...</div>
            );
        }
        else{
            page = (
                <Redirect to="/login"/>
            );
        }
        return page;
        
    }
}
export default SignOut;