import React, { Component } from 'react';
import {Link} from "react-router-dom";
class UsersAll extends Component {
    state = {
        loading: true,
        users:[],
        sites:[],
        containers:[],
        UserId:-1
    }
    componentDidMount = async () =>{
        const {databaseRequest,setPage,params}=this.props;
        console.log(params);
        setPage(`Subscriptions - System`);
        var sites = await databaseRequest("/system/sites","GET");
        var containers = await databaseRequest("/system/containers","GET");
        var users = await databaseRequest("/users","GET");
        this.setState({
            loading: false,
            sites: sites.length>0?sites:[],
            containers: containers.length>0?containers:[],
            users: users.length>0?users:[],
            UserId: params.UserId
        });
    }
    username = (Id)=>{
        for(let i=0; i<this.state.users.length; i++){
            if(this.state.users[i].Id==Id){
                return this.state.users[i].Name;
            }
        }
        return "NA"
    }
    siteName = (Id)=>{
        for(let i=0; i<this.state.sites.length; i++){
            if(this.state.sites[i].Id==Id){
                return this.state.sites[i].Name;
            }
        }
        return "NA"
    }
    tagCloud = () =>{
        if(this.state.loading){
            return (
                <div className="w-100 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">Loading systems...</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )
        }
        else{
            return (
                <div>
                <div className="row mb-4">
                    <div className="tag-selected">{this.username(this.state.UserId)}</div>
                        <div className="col p-0">
                            <Link to="/faults/subscriptions"><button type="button" className="btn btn-info float-right">Back to User Selection</button></Link>
                        </div>
                </div>
                <div className="row">
                    <div>
                        {this.state.containers.map((row, i) => {
                                return (
                                    <Link key={i} to={`/faults/subscriptions/${this.state.UserId}/${row.Id}`}><div className="tag">{`[${this.siteName(row.SiteId)}/${row.Name}]`}</div></Link>
                                )
                        })
                    }  
                    </div>
                </div>
                </div>
            );
        }
    }
    render(){
        return (
            <main role="main" className="container">
                <h2><i className="fe fe-bell"></i> System Selection <i className="fe fe-package"></i></h2>
                <div className="row">
                    <div className="col p-0">
                        <p>Assign system to subscription.</p>
                    </div>
                </div>

                {this.tagCloud()}
                
            </main>

            
        )
    }
}

export default UsersAll;