import React, { Component } from 'react';
import { Link } from "react-router-dom";
import NavItem from './NavItem.js'
import NavDropdown from './NavDropdown.js';
import NavDropdownItem from './NavDropdownItem.js';

class NavBar extends Component {
    componentDidMount = () =>{
        const {setPage}=this.props;
        setPage("Home");
    }
    render (){
        const {props}=this;
        const access = localStorage.getItem("access");
        if(!this.props.visible && (window.location.pathname==="/" || window.location.pathname==="/home")){
            return (<div/>)
        }
        return (
        <nav className="navbar navbar-expand-md fixed-top navbar-dark bg-dark bd-navbar">
        <Link className="navbar-brand" to="/"><img src="/mlink-solid.svg" alt="" width="40" height="40" /></Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav mr-auto">
                <NavItem name="Home" to="/home" icon="trending-up"/>       
                {access>=50?(     
                <NavDropdown name="Systems" to="/systems" icon="cpu" {...props}>
                    <NavDropdownItem name="Sites" to="/systems/sites"  icon="compass"/>
                    <NavDropdownItem name="Containers" to="/systems/containers"  icon="package"/>
                    <NavDropdownItem name="Dashboards" to="/systems/dashboards"  icon="airplay"/>
                </NavDropdown>
                ):(<div></div>)}


                {access>50?(
                <NavDropdown name="Statistics" to="/issues" icon="bar-chart-2" {...props}>
                    <NavDropdownItem name="Rinses" to="/issues/registry"  icon="droplet"/>
                    <NavDropdownItem name="CIP" to="/issues/locations"  icon="shield"/>
                    <NavDropdownItem name="Equipment" to="/issues/transfer"  icon="shopping-cart"/>
                </NavDropdown>
                ):(<div></div>)}

            {access>10?(
                <NavDropdown name="Alarms" to="/faults" icon="bell" {...props}>
                    {access>50?(<NavDropdownItem name="Default Alarms" to="/faults/default"  icon="list"/>):(<div></div>)}
                    {access>50?(<NavDropdownItem name="Specific Alarms" to="/faults/specific"  icon="edit"/>):(<div></div>)}
                    <NavDropdownItem name="Subscriptions" to="/faults/subscriptions"  icon="phone-incoming"/>
                </NavDropdown>
            ):(<div></div>)}
            {access>10?(
                <NavDropdown name="Issues" to="#" icon="alert-circle" {...props}>
                    <NavDropdownItem name="Active" to="/issues/active"  icon="minus-circle"/>
                    {/*<NavDropdownItem name="Unviewed" to="/issues/registry"  icon="help-circle"/>*/}
                    <NavDropdownItem name="Outstanding" to="/issues/outstanding"  icon="x-circle"/>
                    <NavDropdownItem name="Resolved" to="/issues/resolved"  icon="check-circle"/>
                    <NavDropdownItem name="Reports" to="/issues/reports"  icon="file-text"/>
                </NavDropdown>
                ):(<div></div>)}

                {access>50?(
                <NavDropdown name="Inventory" to="#" icon="credit-card" {...props}>
                    <NavDropdownItem name="Registry" to="/inventory/registry"  icon="list"/>
                    <NavDropdownItem name="Locations" to="/inventory/locations"  icon="globe"/>
                    <NavDropdownItem name="Transfer" to="/inventory/transfer"  icon="shuffle"/>
                </NavDropdown>
                ):(<div></div>)}

                {access>50?(
                <NavDropdown name="Access" to="#" icon="share-2" {...props}>
                    <NavDropdownItem name="All Users" to="/users"  icon="user-check"/>
                    <NavDropdownItem name="User Groups" to="/inventory/registry"  icon="users"/>
                </NavDropdown>
                ):(<div></div>)}

                <NavDropdown name="Account" to="#" icon="user" {...props}>
                    <NavDropdownItem name="Your Account" to="/account"  icon="user"/>
                    {access>50?(<NavDropdownItem name="Expenses" to="/account/expenses"  icon="dollar-sign"/>):(<div></div>)}
                </NavDropdown>
                
                
            </ul>
            <ul className="navbar-nav">
                <NavItem name="Logout" to="/logout" icon="log-out"/>
            </ul>
        </div>
        </nav>
        );
    }
}
export default NavBar;