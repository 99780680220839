import React, { Component, createRef } from 'react';
import { Link } from "react-router-dom";
import socketIOClient from "socket.io-client";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import option from '../Dashboards/GaugeOptions.js';
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);

class Dashboard extends Component {
    state = {
        loading: true,
        data: [],
        updateInt: null,
        Temp: 0,
        Rinsed: 0,
        Total: 0,
        Age: 0     
    }
    age = (values) =>{
        var maxAge = 10000000000;
        for (let i = 0; i < values.length; i++) {
            if (maxAge > values[i].Age) {
                maxAge = values[i].Age;
            }
        }
        return maxAge;
    }
    componentDidMount() {
        const {values,Container,Species,Site}=this.props;       
        let t = this;
        t.setState({Total: values.Total, Rinsed: values.Rinsed, Temp: values.Temp, Flowrates: values.Flowrates})     
    }

    graph = () => {
        const {Site,Species,Token,values} = this.props;
        const access = localStorage.getItem("access");
        var options = {
            chart: {
                type: 'solidgauge',
                height: '110%',
                backgroundColor: '#ffffff',
                margin: [0,0,0,0],
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            title:{
                text:''
            },  
            tooltip: {
                enabled: false
            },
            pane: {
                startAngle: 0,
                endAngle: 360,
                background: [{ 
                    outerRadius: '112%',
                    innerRadius: '80%',
                    backgroundColor: Highcharts.color(Highcharts.getOptions().colors[0])
                        .setOpacity(0.3)
                        .get(),
                    borderWidth: 0
                }]
            },
            
            yAxis: {
                min: -2,
                max: 20,
                lineWidth: 0,
                tickPositions: [],
                stops: [ [0, '#ec8d7c'], [0.12, '#ecd67c'], [0.18, '#87ec7c'], [0.45, '#87ec7c'], [0.63, '#ecd67c'], [0.77, '#ec8d7c'], [1, '#ec8d7c'] ]
            },
            plotOptions: {
                solidgauge: {
                    linecap: 'round',
                    stickyTracking: false,
                    rounded: true,
                    dataLabels: {
                        y: -25,
                        borderWidth: 0,
                        useHTML: true
                    }
                },
                series:{
                    animation: false
                }
            },
            series: [{
                name: 'Level',
                enableMouseTracking: false,
                data: [{
                    color: Highcharts.getOptions().colors[0],
                    valueSuffix: '&#176;',
                    radius: '112%',
                    innerRadius: '80%',
                    y: values.Temp
                }],
                dataLabels: {
                    format: '<div style="text-align:center;"><span style="font-size:2em;color:' +
                        ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') + '">{y:.2f}</span>' +
                        '<span style="font-size:18px;color:#999">&#176;C</span></br><span style="font-size:10px;color:#999"></span></div>'
                },
                tooltip: {
                    valueSuffix: '&#176;'
                }
            }]
        };
        var hosesToBePurged = "";
        for(let i=0; i<32; i++){
            if(this.bit(values.HosePurged,i)){
                hosesToBePurged += i+" | ";
            }
        }
        hosesToBePurged = hosesToBePurged.substring(0, hosesToBePurged.length - 3);
        return (
            <div className="bg-white shadow mt-3 p-3 rounded ">  
                <Link to={access>10?"d/summary/"+Token+"/"+Species.toLowerCase():"#"}><h2 className="w-100 text-center">{Site} / {Species}</h2></Link>
                <h3 className="text-center">
                    <i className={"fe fe-check-circle "+(values.InProduction?"text-success":"text-muted")} style={{padding: ".6rem", fontSize: "1.5rem"}}></i>
                    <i className={"fe fe-cloud-drizzle "+(values.InCIP?"text-primary":"text-muted")} style={{padding: ".6rem", fontSize: "1.5rem"}}></i>
                    <i className={"fe fe-cloud "+(values.CIPComplete?"text-success":"text-muted")} style={{padding: ".6rem", fontSize: "1.5rem"}}></i>
                    <i className={"fe fe-bell "+(values.Faulted?"text-danger":"text-muted")} style={{padding: ".6rem", fontSize: "1.5rem"}}></i>            
                </h3>
                <p className="w-100 text-center" style={{maxWidth:"300px"}}>
                    {hosesToBePurged}
                </p>

                <HighchartsReact
                    containerProps={{ style: { margin:'auto', height: "180px", width: '160px' } }}
                    options={options}
                    highcharts={Highcharts}
                    allowChartUpdate={true}
                    ref="c1"
                />
                <h2 className="text-center m-0 p-0">{values.Rinsed.toFixed(0)} / {values.Total.toFixed(0)} <i className="small">({values.Total>0?((100*(values.Rinsed/values.Total)).toFixed(0)):" - "}%)</i></h2>
                <div className="col mt-2">
                    <table className="table m-0">
                        <tbody>
                        {values.Flowrates.map((row, i) => {
                            if(row>0){
                                if(access>10){
                                    return (
                                    <tr key={i}><th className="p-0">Hose {i}</th><td className="p-0 text-right">{row.toFixed(2)}l/m</td></tr>
                                    )
                                }
                            } 
                        })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
    disconnected = () => {
        const {Site,Species,Token,values} = this.props;
        return (
            <div className="bg-white shadow mt-3 p-3 rounded" style={{maxWidth:"300px"}}>  
                <Link to={"d/summary/"+Token+"/"+Species.toLowerCase()}><h2 className="w-100 text-center">{Site} / {Species}</h2></Link>
                <div className="w-100 text-center mt-4 text-danger">
                    <i className="fe fe-alert-octagon" style={{fontSize: "5rem"}}></i>
                    <p className="mt-4">{this.connectionQuality(values.Age)}</p>
                    
                </div>
            </div>
        );
    }
    render() {
        const {values}=this.props;
        if(values.Age < 60000) return this.graph();
        return this.disconnected();
    }
    connectionQuality = (elapsed) => {
        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 60; //Two months
        var msPerYear = msPerDay * 365*2; //Two years
        if(elapsed <= 0){
            return "Not Connected"
        }
        if(elapsed < 10000){
            return "";
        }
        if(elapsed < 31000){
            return "Connected: Not Polling";
        }
        if (elapsed < msPerMinute) {
            return `Warning: Updated ${Math.round(elapsed/1000)} second${Math.round(elapsed/1000)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerHour) {
            return `Warning: Updated ${Math.round(elapsed/msPerMinute)} minute${Math.round(elapsed/msPerMinute)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerDay ) {
            return `Warning: Updated ${Math.round(elapsed/msPerHour)} hour${Math.round(elapsed/msPerHour)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerMonth) {
            return `Warning: Updated ${Math.round(elapsed/msPerDay)} day${Math.round(elapsed/msPerDay)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerYear) {
            return `Warning: Updated ${Math.round(elapsed/msPerMonth)} month${Math.round(elapsed/msPerMonth)>1?"s":""} ago`;   
        }

        else {
            return `Warning: Updated ${Math.round(elapsed/msPerYear)} year${Math.round(elapsed/msPerYear)>1?"s":""} ago`;   
        }
    }
    DINTtoLongDate = function(dint){
        if(typeof dint==='undefined'){
            return "";
        }
        var dd=Number(this.pad(dint).substr(6,2));
        var mm=Number(this.pad(dint).substr(4,2));
        var yyyy=Number(this.pad(dint).substr(0,4));
    
        var month = new Array();
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";
        
        return month[mm-1]+" "+this.ordinal_suffix_of(dd)+", "+yyyy;
    }  
    ordinal_suffix_of = function(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }
    DINTtoDate = function (dint) {
        if(typeof dint==='undefined'){
            return "";
        }
        return this.pad(dint, 8).substr(6, 2) + '/' + this.pad(dint, 8).substr(4, 2) + '/' + this.pad(dint, 8).substr(0, 4);
    }
    DINTtoTime = function (dint) {
        if(typeof dint==='undefined'){
            return "";
        }
        return this.pad(dint, 6).substr(0, 2) + ':' + this.pad(dint, 6).substr(2, 2) + ':' + this.pad(dint, 6).substr(4, 2);
    }
    pad = function (num, places = 2) {
        var str = num.toString();
        while (str.length < places) {
            str = "0" + str;
        }

        return str;
    }

    roundToPlaces = function (num, places) {
        var div = "1";
        if (places < 0) { return 0; }
        if (places > 10) { return 0; }
        for (let i = 0; i < places; i++) {
            div = div + "0";
        }
        var base = Number(div);
        return Math.round(num * base) / base;
    }
    bit = function (num, place) {
        var mask = 1 << place;
        return (num & mask) != 0;
    }
}

export default Dashboard;