import React, { Component } from 'react';
import {Link} from "react-router-dom";
import socketIOClient from "socket.io-client";

class Containers extends Component {
    state = {
        loading: true,
        search: "",
        data: []
    }
    onSearch = (event) => {
        const {databaseRequest}=this.props;
        this.setState({loading: true});
        var search = event.target.value;
        databaseRequest(`/system/containers?Name:like=%${search}%`,"GET").then((data)=>{
                this.setState({
                    loading: false,
                    data: data.length>0?data:[]
                })
        });
    }
    siteName = (Id) =>{
        const {sites} = this.state;
        for(let i=0; i<sites.length; i++){
            if(Id==sites[i].Id){
                return sites[i].Name;
            }
        }
        return "Not allocated";
    }
    updateConnections = (connections)=>{
        var data = this.state.data;
        var change = false;
        for(let i =0; i<connections.length; i++){
            for(let j=0; j<this.state.data.length; j++){
                if(data[j].Name==connections[i].ContainerName){
                    data[j].Connection=connections[i].Age;
                    change=true;
                }
            }
        }
        if(change){
            this.setState({data:data});
        }
    }
    componentDidMount = () =>{
        const {databaseRequest,setPage, socketServer}=this.props;
        const socket = socketIOClient(socketServer);
        let t=this;
        socket.on('connect', ()=>{
            setTimeout(()=>{
                socket.emit("containers-online",function(data){
                    t.updateConnections(data);
                });
                setInterval(function(){
                    socket.emit("containers-online",function(data){
                        t.updateConnections(data);
                    });
                }, 5000);
            }, 1000);

        })
        

        setPage(`Containers`);
        let sites = [];
        databaseRequest("/system/sites","GET").then((data)=>{ 
            sites = [...data];
            return databaseRequest("/system/containers","GET");
        }).then((data)=>{
            for(let i=0; i<data.length; i++){
                data[i].Connection = -1;
            }
            this.setState({
                loading: false,
                data: data.length>0?data:[],
                sites: sites
            })

        });
    }
    table = () => {    
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading containers...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    <table className="table table-striped">
                    <thead className="thead-dark">
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Site</th>
                        <th scope="col">Last Update</th>
						<th scope="col">Order</th>						  
                        <th scope="col">Token</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.data.map((row, i) => {
                        return (
                        <tr key={i}>
                            <th scope="row" >{1+i}</th>
                            <td><Link to={`/systems/containers/${row.Id}`}>{row.Name}</Link></td>
                            <td>{this.siteName(row.SiteId)}</td>
                            <td title={row.Connection>0?((row.Connection/1000).toFixed(0)+"s ago"):("-")} className={this.connectionQualityClass(row.Connection)}>{this.connectionQuality(row.Connection)}</td>
							<td>{row.WebsiteOrder}</td>						   
                            <td>{(row.Token)}</td>
                        </tr>)
                    })}
                    </tbody>
                    </table>
                )
            }
    }
    connectionQualityClass = (elapsed) =>{
        if(elapsed < 0){
            return "text-danger";
        }
        if(elapsed < 60000){
            return "text-info";
        }
        if(elapsed < 90000){
            return "text-warning";
        }
        return "text-danger";
    }
    connectionQuality = (elapsed) => {
        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 60; //Two months
        var msPerYear = msPerDay * 365*2; //Two years
        if(elapsed < 0){
            return "Not Connected"
        }
        if(elapsed < 10000){
            return "Connected: Polling";
        }
        if(elapsed < 61000){
            return "Connected: Not Polling";
        }
        if (elapsed < msPerMinute) {
            return `${Math.round(elapsed/1000)} second${Math.round(elapsed/1000)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerHour) {
            return `${Math.round(elapsed/msPerMinute)} minute${Math.round(elapsed/msPerMinute)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerDay ) {
            return `${Math.round(elapsed/msPerHour)} hour${Math.round(elapsed/msPerHour)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerMonth) {
            return `${Math.round(elapsed/msPerDay)} day${Math.round(elapsed/msPerDay)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerYear) {
            return `${Math.round(elapsed/msPerMonth)} month${Math.round(elapsed/msPerMonth)>1?"s":""} ago`;   
        }

        else {
            return `${Math.round(elapsed/msPerYear)} year${Math.round(elapsed/msPerYear)>1?"s":""} ago`;   
        }
    }
    render(){
        return (
            <main role="main" className="container">
                <h2><i className="fe fe-package"></i> Containers</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <form className="form-inline">
                            <input className="form-control mr-sm-2" type="search" onChange={this.onSearch} placeholder="Search" aria-label="Search"/>                      
                        </form>
                    </div>
                    <div className="col p-0">
                        <Link to="/systems/containers/-1"><button type="button" className="btn btn-success float-right">New Container</button></Link>
                    </div>
                </div>
                <div className="row">
                    {this.table()}

                </div>
            </main>

            
        )
    }
}
export default Containers;