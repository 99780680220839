import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";

class SitesEdit extends Component {
    state = {
        loading: true,
        loadingMsg: "Loading sites...",
        redirect: "",
        Name: "",
        Address: "",
        CompanyName: "",
        Guid: ""
    }
    onChange = (e) => {
        let data = this.state.data;
        if(e.target.name in data){
            data[e.target.name]=e.target.value;
            this.setState({ data: data});  
        }
    };
    saveBtn = () =>{
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: "Saving "+this.data("Name")+"..."
        });

        var req = {
            url: `/system/sites/${params.SiteId}`,
            method: "PUT"
        }
        if(params.SiteId==="-1"){
            req.url="/system/sites";
            req.method="POST";
        }
        databaseRequest(req.url,req.method, 
            JSON.stringify(this.state.data)
        ).then((data)=>{
            setTimeout(function(){
                setPage(`${data.Name}`);
                t.setState({
                    loading: false,
                    loadingMsg: "Loading sites...",
                    data: data,
                    redirect: "/systems/sites"
                });
            }, 250);
        });
    }
    deleteBtn = () =>{
        const {databaseRequest,params}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: "Deleting "+this.data("Name")+"..."
        });
        databaseRequest(`/system/sites/${params.SiteId}`,"DELETE").then((data)=>{
            setTimeout(function(){
                t.setState({
                    loading: false,
                    loadingMsg: "Loading sites...",
                    redirect: "/systems/sites"
                });
            }, 1000);
        });

    }
    componentDidMount(){
        const {databaseRequest,params,setPage}=this.props;
        setPage(`Sites`);
        let entities = [];
        databaseRequest("/system/entities","GET").then((data)=>{ 
            entities = [...data];
            if(params.SiteId==="-1"){
                this.setState({
                    data:{
                        Name: "--New Site--",
                        CompanyName: "",
                        Address: "",
                        EntityId: 0
                    },
                    loadingMsg: "Loading sites...",
                    loading: false,
                    entities: entities
                });
            }
            else{
                databaseRequest(`/system/sites/${params.SiteId}`,"GET").then((data)=>{
                        setPage(`${data.Name}`);
                        this.setState({
                            loading: false,
                            data: data,
                            entities: entities
                        });
                });
            }
        });


    }
    data = (key) =>{
        if(key in this.state.data){  
            return this.state.data[key];
        }
        else{
            return "";
        }
    }
    loading = () =>{
        return (
            <div className="w-100 mt-5 text-center">
                <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }
    page = () =>{
        if(this.state.loading){
            return this.loading();
        }
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        return (
            <main role="main" className="container">
            <div className="p-5">
                <h2><i className="fe fe-compass"></i> Sites</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <h1 className="border-bottom">{this.data("Name").length>0?this.data("Name"):"-- New Site --"}</h1>
                    </div>
                    <div className="col p-0">
                       <Link to="/systems/sites"><button type="button" className="btn btn-info float-right">Back to all Sites</button></Link>
                 </div>
                </div>
                <div className="row">
                    <form className="w-100">
                    <div className="form-row">
                        <div className="form-group col-md-3">
                        <label htmlFor="input1">Site Name</label>
                        <input type="text" className="form-control" id="input1" placeholder="Site Name" name="Name" value={this.data("Name")} onChange={this.onChange}/>
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="input2">Company Name</label>
                            <input type="text" className="form-control" id="input2" placeholder="Company Name"  name="CompanyName" value={this.data("CompanyName")} onChange={this.onChange}/>
                        </div>    
                    </div>  
                    <div className="form-row">  
                        <div className="form-group col-md-3">
                            <label>Managing Site</label>
                            <select className="form-control" name="EntityId" value={this.data("EntityId")} onChange={this.onChange}>
                                <option value="-1">Not Selected</option>
                                {this.state.entities.map((row, i) => {
                                        return (<option  key={i} value={row.Id}>{row.Name}</option>)                
                                })}
                            </select>  
                        </div>    
                        <div className="form-group col-md-6">
                            <label htmlFor="input3">Address</label>
                            <input type="text" className="form-control" id="input3" placeholder="Address"  name="Address" value={this.data("Address")} onChange={this.onChange}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label>Latitude</label>
                            <input type="text" className="form-control" placeholder="Latitude"  name="Latitude" value={this.data("Latitude")} onChange={this.onChange}/>
                        </div>
                        <div className="form-group col-md-3">
                            <label>Longitude</label>
                            <input type="text" className="form-control" placeholder="Longitude"  name="Longitude" value={this.data("Longitude")} onChange={this.onChange}/>
                        </div>
                    </div>   
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <button type="button" className="btn btn-success" onClick={this.saveBtn}>Save</button>
                            <button type="button" className="btn btn-danger ml-3" data-toggle="modal" data-target="#myModal" >Delete</button>
                            
                            {this.props.params.SiteId!=="-1"?(
                            <Link to={`/history/${this.state.data.Guid}/${btoa(this.props.location.pathname)}`}>
                                <button type="button" className="btn btn-dark ml-3">History</button>
                            </Link>
                            ):(<div></div>)}
 
                        </div>
                    </div>


                    </form>
                </div>
                <div className="modal" id="myModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Site</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete {this.state.Name}?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.deleteBtn}>OK</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </main>
        );

    }
    render() {
        return this.page();
    }
}

export default SitesEdit;