import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";

class ContainersEdit extends Component {
    state = {
        loading: true,
        loadingMsg: "Loading containers...",
        redirect: "",
        Name: "",
        SiteId: "",
		WebsiteOrder: 0,				
        IPAddress: "",
        Slot: "",
        DataToCollect: "",
        Records: 0,
        RinsedOnly: false,
        Guid: ""
    }
    onChange = (e) => {
        let data = this.state.data;
        
        if(e.target.name in data){
            data[e.target.name]=e.target.value;
            this.setState({ data: data});  
        }
    };
    onChangeNumber = (e) => {
        let data = this.state.data;
        
        if(e.target.name in data){
            data[e.target.name]=Number(e.target.value);
            this.setState({ data: data});  
        }
    };
    saveBtn = () =>{
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: "Saving "+this.data("Name")+"..."
        });

        var req = {
            url: `/system/containers/${params.ContainerId}`,
            method: "PUT"
        }
        if(params.ContainerId==="-1"){
            req.url="/system/containers";
            req.method="POST";
        }
        databaseRequest(req.url,req.method, 
            JSON.stringify(this.state.data)
        ).then((data)=>{
            setTimeout(function(){
                setPage(`${data.Name}`);
                t.setState({
                    loading: false,
                    loadingMsg: "Loading containers...",
                    data: data,
                    redirect: "/systems/containers"
                });
            }, 250);
        });
    }
    deleteBtn = () =>{
        const {databaseRequest,params}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: "Deleting "+this.data("Name")+"..."
        });
        databaseRequest(`/system/containers/${params.ContainerId}`,"DELETE").then((data)=>{
            setTimeout(function(){
                t.setState({
                    loading: false,
                    loadingMsg: "Loading containers...",
                    redirect: "/systems/containers"
                });
            }, 1000);
        });

    }
    componentDidMount(){
        const {databaseRequest,params,setPage}=this.props;
        setPage(`Containers`);
        let sites = [];
        databaseRequest("/system/sites","GET").then((data)=>{ 
            sites = [...data];
        if(params.ContainerId==="-1"){
            this.setState({
                data:{
                    Name: "--New Container--",
					WebsiteOrder: 0,				
                    Token: this.token(20),
                    Records: "",
                    IPAddress: "",
                    Slot: "",
                    SiteId: "",
                    BeefPrice: 0,
                    LambPrice: 0,
                    PorkPrice: 0
                },
                loadingMsg: "Loading containers...",
                loading: false,
                sites: sites
            });
        }
        else{
            databaseRequest(`/system/containers/${params.ContainerId}`,"GET").then((data)=>{
                    setPage(`${data.Name}`);
                    this.setState({
                        loading: false,
                        data: data,
                        sites: sites
                    });
            });
            
        }
    });

    }

    download = (day)=>{
        const {socketServer}=this.props;
        fetch(`${socketServer}/download/${this.state.data.Name}/${day}`).then((response)=>{
            if(response.status===401){
                throw response.status;
              }
              return response.json();
        }).then((data) => {
            alert(data.Message);
        });
    }
    update = ()=>{
        const {socketServer}=this.props;
        fetch(`${socketServer}/update/${this.state.data.Name}`).then((response)=>{
            if(response.status===401){
                throw response.status;
              }
              return response.json();
        }).then((data) => {
            alert(data.Message);
        });
    }
    restart = ()=>{
        const {socketServer}=this.props;
        fetch(`${socketServer}/restart/${this.state.data.Name}`).then((response)=>{
            if(response.status===401){
                throw response.status;
              }
              return response.json();
        }).then((data) => {
            alert(data.Message);
        });
    }
    data = (key) =>{
        if(key in this.state.data){  
            return this.state.data[key];
        }
        else{
            return "";
        }
    }
    loading = () =>{
        return (
            <main role="main" className="container">
                <div className="w-100 mt-5 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </main>
        )
    }
    bit = (num, place) =>{
        var mask = 1 << place; 
        return(num & mask) != 0; 
    };

    set = (num, place) =>{
        var mask = 1 << place; 
        return num |= mask;
    };
    clear = (num, place) => {
        var mask = 1 << place; 
        return num &= ~mask;
    };
    token = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };
    dataToCollect = (e) => {
        let data = this.state.data;
        var spl = e.target.name.split(":");
        var name = spl[0];
        var bitIndex = Number(spl[1]);
        var current = this.bit(data[name], bitIndex);
        

        

        if(current){
            data[name]=this.clear(data[name], bitIndex);
        }
        else{
            data[name]=this.set(data[name], bitIndex);
        }
        console.log(data);
        this.setState({ data: data});  
    };
    page = () =>{
        if(this.state.loading){
            return this.loading();
        }
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        return (
            <main role="main" className="container">
            <div className="p-5">
                <h2><i className="fe fe-package"></i> Containers</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <h1 className="border-bottom">{this.data("Name").length>0?this.data("Name"):"-- New Container --"}</h1>
                    </div>
                    <div className="col p-0">
                       <Link to="/systems/containers"><button type="button" className="btn btn-info float-right">Back to all Containers</button></Link>
                    </div>
                </div>
                <div className="row">
                    <form className="w-100">
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label>Container Name</label>
                            <input type="text" className="form-control" placeholder="Container Name" name="Name" value={this.data("Name")} onChange={this.onChange}/>
                        </div>
                        <div className="form-group col-md-3">
                            <label>Allocated Site</label>
                            <select className="form-control" name="SiteId" value={this.data("SiteId")} onChange={this.onChange}>
                                <option value="-1">Master Site</option>
                                {this.state.sites.map((row, i) => {
                                    return (<option  key={i} value={row.Id}>{row.Name}</option>)
                                })}
                            </select>  
                        </div>
                        <div className="form-group col-md-2">
                            <label>Dashboard Order</label>
                            <input type="text" className="form-control" placeholder="Dashboard Order" name="WebsiteOrder" value={this.data("WebsiteOrder")} onChange={this.onChangeNumber}/>
                        </div>
                        <div className="form-group col-md-3">
                            <label>Token (Readonly)</label>
                            <input type="text" disabled={true} read-only="true" className="form-control" placeholder="IP Address" name="Token" value={this.data("Token")} />
                            <small className="text-muted">
                            Copy this token to SiteLink configuration file
                            </small>
                        </div>    
                    </div>


                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label>Records</label>
                            <input type="text" className="form-control" placeholder="Records" name="Records" value={this.data("Records")}  onChange={this.onChange}/>
                            <small className="text-muted">
                            The maximum number of bodies the container can record
                            </small>
                        </div>
                        <div className="form-group col-md-2">
                            <label>IP Address</label>
                            <input type="text" className="form-control" placeholder="IP Address" name="IPAddress" value={this.data("IPAddress")}  onChange={this.onChange}/>
                        </div>
                        <div className="form-group col-md-1">
                            <label>Slot</label>
                            <input type="text" className="form-control" placeholder="Slot" name="Slot" value={this.data("Slot")}  onChange={this.onChange}/>
                        </div>
                    </div>    

                    <div className="form-row">
                        <div className="form-group col-md-2">
                            <label>Beef Rinse Price</label>
                            <input type="number" className="form-control text-right" placeholder="Beef Price" name="BeefPrice" value={this.data("BeefPrice")} onChange={this.onChangeNumber}/>
                        </div>
                        <div className="form-group col-md-2">
                            <label>Lamb Rinse Price</label>
                            <input type="number" className="form-control text-right" placeholder="Lamb Price" name="LambPrice" value={this.data("LambPrice")} onChange={this.onChangeNumber}/>
                        </div>
                        <div className="form-group col-md-2">
                            <label>Pork Rinse Price</label>
                            <input type="number" className="form-control text-right" placeholder="Pork Price" name="PorkPrice" value={this.data("PorkPrice")} onChange={this.onChangeNumber}/>
                        </div>        
                    </div>



                    <div className="form-row">
                        <div className="form-check col-md-3">            
                            <input type="checkbox" className="form-check-input" name="RinsedOnly:0" checked={this.bit(this.data("RinsedOnly"),0)} onChange={this.dataToCollect}/>
                            <label className="form-check-label font-weight-bold">Rinsed Only</label>
                            <p>
                            <small className="text-muted">
                            If selected data uploaded will only included rinsed bodies
                            </small>
                            </p>
                        </div>
                        {this.bit(this.data("DataToCollect"),5)?(
                        <div className="form-group col-md-3">
                            <a href={`/d/summary/${this.state.data.Token}/lamb`} target="_blank"><button type="button" className="btn btn-dark float-right">View Lamb Dashboard</button></a>
                        </div>
                        ):(<div/>)}
                        {this.bit(this.data("DataToCollect"),4)?(
                        <div className="form-group col-md-3">    
                            <a href={`/d/summary/${this.state.data.Token}/beef`} target="_blank"><button type="button" className="btn btn-dark">View Beef Dashboard</button></a>
                        </div>
                        ):(<div/>)}
                        
                    </div>                    
                    <div className="form-group">
                        <label>Data Connections</label>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" name="DataToCollect:0" checked={this.bit(this.data("DataToCollect"),0)} onChange={this.dataToCollect}/>
                            <label className="form-check-label font-weight-bold">PLC Connection</label>
                        </div>
                        <div className="form-check">         
                            <input type="checkbox" className="form-check-input" name="DataToCollect:1" checked={this.bit(this.data("DataToCollect"),1)} onChange={this.dataToCollect}/>    
                            <label className="form-check-label font-weight-bold">Poll Tags</label>  
                        </div>
                        <div className="form-check">       
                            <input type="checkbox" className="form-check-input" name="DataToCollect:2" checked={this.bit(this.data("DataToCollect"),2)} onChange={this.dataToCollect}/>
                            <label className="form-check-label font-weight-bold">DAQ Download</label>
                        </div>
                        <div className="form-check">       
                            <input type="checkbox" className="form-check-input" name="DataToCollect:3" checked={this.bit(this.data("DataToCollect"),3)} onChange={this.dataToCollect}/>
                            <label className="form-check-label font-weight-bold">Faults</label>
                        </div>
                        <div className="form-check">      
                            <input type="checkbox" className="form-check-input" name="DataToCollect:4" checked={this.bit(this.data("DataToCollect"),4)} onChange={this.dataToCollect}/>
                            <label className="form-check-label font-weight-bold">Beef Dashboard </label>
                        </div>
                        <div className="form-check">      
                            <input type="checkbox" className="form-check-input" name="DataToCollect:5" checked={this.bit(this.data("DataToCollect"),5)} onChange={this.dataToCollect}/>
                            <label className="form-check-label font-weight-bold">Lamb Dashboard </label>
                        </div>
                        <div className="form-check">      
                            <input type="checkbox" className="form-check-input" name="DataToCollect:6" checked={this.bit(this.data("DataToCollect"),6)} onChange={this.dataToCollect}/>
                            <label className="form-check-label font-weight-bold">MLLive v2 AOI </label>
                        </div>
                        <div className="form-check">      
                            <input type="checkbox" className="form-check-input" name="DataToCollect:7" checked={this.bit(this.data("DataToCollect"),7)} onChange={this.dataToCollect}/>
                            <label className="form-check-label font-weight-bold">Auto DAQ Download </label>
                        </div>
                    </div>    
                    
                    <div className="form-row">
                        <div className="form-group col-md">
                            <button type="button" className="btn btn-success" onClick={this.saveBtn}>Save</button>
                            <button type="button" className="btn btn-danger ml-3" data-toggle="modal" data-target="#myModal" >Delete</button>
                            
                            {this.props.params.ContainerId!=="-1"?(
                            <Link to={`/history/${this.state.data.Guid}/${btoa(this.props.location.pathname)}`}>
                                <button type="button" className="btn btn-dark ml-3">History</button>
                            </Link>
                            ):(<div></div>)}

                        </div>
                    </div>
                    <div className="form-row mt-5">
                        <h1>SiteLink Controls</h1>       
                    </div>
                    <div className="form-row">           
                        <div className="form-group col mb-0">
                            <button type="button" className="btn btn-primary mt-3 mb-3 mr-3" onClick={(e)=>{this.download(1)}}>Download 1 Day Ago</button>
                            <button type="button" className="btn btn-primary m-3" onClick={(e)=>{this.download(2)}}>Download 2 Day Ago</button>
                            <button type="button" className="btn btn-primary m-3" onClick={(e)=>{this.download(3)}}>Download 3 Day Ago</button>
                            <button type="button" className="btn btn-primary m-3" onClick={(e)=>{this.download(4)}}>Download 4 Day Ago</button>
                            <button type="button" className="btn btn-primary m-3" onClick={(e)=>{this.download(5)}}>Download 5 Day Ago</button>
                       
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col">
                            <button type="button" className="btn btn-warning mt-3 mb-3 mr-4"  onClick={(e)=>{this.restart()}}>Restart SiteLink</button>    
                            <button type="button" className="btn btn-danger m-3"  onClick={(e)=>{this.update()}}>Update SiteLink</button>                             
                        </div>
                    </div>

                    </form>
                </div>
                <div className="modal" id="myModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Container</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete {this.state.Name}?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.deleteBtn}>OK</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </main>
        );

    }
    render() {
        return this.page();
    }
}

export default ContainersEdit;