import React, { Component, useCallback } from 'react';
import Dropzone from 'react-dropzone'
import {Link, Redirect} from "react-router-dom";
import Autocomplete from "../../Autocomplete";
import DatePicker, {registerLocale} from "react-datepicker";
import enAU from 'date-fns/locale/en-AU';
import "react-datepicker/dist/react-datepicker.css";

class ExpensesEdit extends Component {
    state = {
        loading: true,
        loadingMsg: "Loading expenses...",
        redirect: "",
        upload: "",
        error: "",
        buttons:{
            history: false,
            save: false,
            delete: false
        },
        sites: [],
        CompanyNames: [],
        expenseDate: new Date(),
        data:{  
            CompanyName: "",
            DateTime: new Date().getTime(),
            Description: "",
            Amount: "0.00",
            Reconciled: 0,
            SiteId: -1
        },
    }




    onChange = (e) => {
        let data = this.state.data;
        if(e.target.name in data){
            data[e.target.name]=e.target.value;
            this.setState({ data: data});  
        }
    };
    onFileUpload = (e) =>{
        let d = this.state.data;
        const {databaseRequest}=this.props;
        const files = e.target.files;

        this.setState({
            buttons:{
                history: true,
                save: true,
                delete: true
            }
        });
        const formData = new FormData();
        formData.append('receipt', files[0]);
        databaseRequest(`/upload`,`POST`,formData,true).then((data)=>{
            if(typeof data.msg === 'undefined'){
                d.ReceiptFile = data.file;
                this.setState({
                    upload: "/"+data.file,
                    buttons:{
                        history: false,
                        save: false,
                        delete: false
                    }
                });
            }
            else{
                this.setState({
                    error: data.msg,
                    buttons:{
                        history: false,
                        save: false,
                        delete: false
                    }
                });
            }
        });
    }
    saveBtn = () =>{
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: "Saving "+this.data("Name")+"..."
        });

        var req = {
            url: `/account/expenses/${params.ExpenseId}`,
            method: "PUT"
        }
        if(params.ExpenseId==="-1"){
            req.url="/account/expenses";
            req.method="POST";
        }
        databaseRequest(req.url,req.method, 
            JSON.stringify(this.state.data)
        ).then((data)=>{
            setTimeout(function(){
                setPage(`${data.Description}`);
                t.setState({
                    loading: false,
                    loadingMsg: "Loading expenses...",
                    data: data,
                    redirect: "/account/expenses"
                });
            }, 250);
        });
    }
    deleteBtn = () =>{
        const {databaseRequest,params}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: "Deleting "+this.data("Name")+"..."
        });
        databaseRequest(`/account/expenses/${params.ExpenseId}`,"DELETE").then((data)=>{
            setTimeout(function(){
                t.setState({
                    loading: false,
                    loadingMsg: "Loading expenses...",
                    redirect: "/account/expenses"
                });
            }, 1000);
        });

    }
    componentDidMount(){
        const {databaseRequest,params,setPage}=this.props;
        

        setPage(`Expenses`);
        let sites = [];
        let CompanyNames = [];
        databaseRequest("/account/expenses","GET").then((data)=>{ 
            for(let i=0; i<data.length; i++){
                CompanyNames.push(data[i].CompanyName);
            }
            
            return databaseRequest("/system/sites","GET");
        }).then((data)=>{ 
            sites = [...data];
            if(params.ExpenseId==="-1"){
                this.setState({
                    loadingMsg: "Loading expenses...",
                    loading: false,
                    sites: sites,
                    CompanyNames: CompanyNames
                });
            }
            else{
                databaseRequest(`/account/expenses/${params.ExpenseId}`,"GET").then((data)=>{
                        setPage(`${data.Description}`);
                        data.Amount = (data.Amount?data.Amount:0).toFixed(2);
                        
                        var buttons = {
                            history: false,
                            save: false,
                            delete: false
                        };

                        if(data.Reconciled==1){
                            buttons = {
                                history: false,
                                save: true,
                                delete: true
                            };
                        }
                        this.setState({
                            upload: data.ReceiptFile.length>0?"/"+data.ReceiptFile:"",
                            loading: false,
                            data: data,
                            expenseDate: data.DateTime?new Date(data.DateTime):new Date(),
                            sites: sites,
                            CompanyNames: CompanyNames,
                            buttons: buttons
                        });
                });
            }
        });


    }
    dateChange = date => {
        let data = this.state.data;
        data.DateTime = date.getTime();
        this.setState({
          expenseDate: date,
          data:data
        });
      };
    data = (key) =>{
        if(key in this.state.data){  
            return this.state.data[key];
        }
        else{
            return "";
        }
    }
    loading = () =>{
        return (
            <div className="w-100 mt-5 text-center">
                <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }
    upload = () => {
        if(this.state.upload.length > 0){
            return (<div className="w-100 m-5 rounded border border-dark p-4 text-center"><img width="100"src={this.state.upload} data-toggle="modal" data-target="#image"/></div>)
        }
        else{
            return (<div className="form-group col-md-5">
                        <label>Receipt</label>
                        
                        

                        <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
                            {({getRootProps, getInputProps}) => (
                                <section>
                                <div {...getRootProps()}  className="rounded border border-dark p-4 text-center">
                                    <input {...getInputProps()}  name="File"  onChange={this.onFileUpload} />
                                    <p>Click here and select receipt file</p>
                                </div>
                                </section>
                            )}
                            </Dropzone>
                            <p className="text-danger">{this.state.error}</p>   
                    </div>);
        }

    }
    page = () =>{
        if(this.state.loading){
            return this.loading();
        }
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        return (
            <main role="main" className="container">
            <div className="p-5">
                <h2><i className="fe fe-dollar-sign"></i> Expenses</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <h1 className="border-bottom">{this.data("Description").length>0?this.data("Description"):"-- New Expense --"}</h1>
                    </div>
                    <div className="col p-0">
                       <Link to="/account/expenses"><button type="button" className="btn btn-info float-right">Back to all Expenses</button></Link>
                 </div>
                 
                </div>
                <div className="row">
                    <form className="w-100">
                    <div className="form-row">  
                        <div className="form-group col-md-5">
                            <label>Expense Date</label><div>
                            <DatePicker dateFormat="dd/MM/yyyy" maxDate={new Date()} placeholderText="Expense Date" className="form-control text-right" selected={this.state.expenseDate} onChange={this.dateChange} />
                            </div>
                        </div>  
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label>Company</label>
                            <Autocomplete
                            value={this.data("CompanyName")}
                            placeholder="Company" name="CompanyName"  onChange={this.onChange}
                            noSuggestions="Not found. Enter new company"
        suggestions={this.state.CompanyNames}
      />
                        </div>
                        <div className="form-group col-md-5">
                            <label htmlFor="input1">Description</label>
                            <input type="text" className="form-control" id="input1" placeholder="Description" name="Description" value={this.data("Description")} onChange={this.onChange}/>
                        </div>

                        <div className="form-group col-md-2">
                            <label>Site Allocation</label>
                            <select className="form-control" name="SiteId" value={this.data("SiteId")} onChange={this.onChange}>
                                <option value="-1">Not allocated</option>
                                {this.state.sites.map((row, i) => {
                                    return (<option  key={i} value={row.Id}>{row.Name}</option>)
                                })}
                            </select>  
                        </div> 


                        <div className="form-group col-md-2">
                            <label>Amount</label>
                           
                            <input type="number" className="form-control text-right" placeholder="Amount"  name="Amount" value={this.data("Amount")} onChange={this.onChange}/>
                        </div>  
                    </div>  

                    <div className="form-row">   
                        {this.upload()}
                             
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <button type="button" disabled={this.state.buttons.save?"disabled":""} className="btn btn-success" onClick={this.saveBtn}>Save</button>
                            <button type="button" disabled={this.state.buttons.delete?"disabled":""} className="btn btn-danger ml-3" data-toggle="modal" data-target="#myModal" >Delete</button>
                            
                            {this.props.params.expenseId!=="-1"?(
                            <Link to={`/history/${this.state.data.Guid}/${btoa(this.props.location.pathname)}`}>
                                <button disabled={this.state.buttons.history?"disabled":""} type="button" className="btn btn-dark ml-3">History</button>
                            </Link>
                            ):(<div></div>)}
 
                        </div>
                    </div>

                    <h3 className="text-danger">{this.state.data.Reconciled==1?"This record has been reconciled":""}</h3>
                    </form>
                </div>

                <div className="modal" id="myModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete expense</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete {this.state.Name}?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.deleteBtn}>OK</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="modal" id="image" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-body">
                            <img className="rounded mx-auto d-block" src={this.state.upload} id="imagepreview" style={{width: "100%"}} />
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" >Close</button>
                        </div>

                        </div>
                    </div>
                </div>

            </div>
            </main>
        );

    }
    render() {
        return this.page();
    }
}

export default ExpensesEdit;