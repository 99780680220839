import React, { Component } from 'react';
import { CSVLink } from "react-csv";
import ReactFileReader from 'react-file-reader';
import csv from 'csv';

class Containers extends Component {
    state = {
        loading: true,
        isSaved: true,
        data: [],
        search: ""
    }
    componentDidMount = () =>{
        const {databaseRequest,setPage}=this.props;
        setPage("Faults - Default");

        databaseRequest("/system/faults/default?limit=2000","GET").then((data)=>{ 
            this.setState({
                data: data.length>0?data:[],
                loading:false
            });
        });    
    }
    onSearch = (event)=>{
        const {databaseRequest}=this.props;
        var search = event.target.value || "";
        search = search.toUpperCase();
        this.setState({loading:true, search: search});

        databaseRequest(`/system/faults/default?limit=2000&Message:or_like=%${search}%&FaultCode:or_like=%${search}%`,"GET").then((data)=>{ 
            this.setState({
                data: data.length>0?data:[],
                loading:false
            });
        });  
    }
    saveBtn = ()=>{
        const {databaseRequest}=this.props;
        let t = this;
        var search = this.state.search;
        t.setState({loading:true, isSaved: false});


        databaseRequest("/system/faults/default","PUT", 
            JSON.stringify(this.state.data)
        ).then((data)=>{
            databaseRequest(`/system/faults/default?limit=2000&Message:or_like=%${search}%&FaultCode:or_like=%${search}%`,"GET").then((data)=>{ 
                this.setState({
                    data: data.length>0?data:[],
                    loading:false,
                    isSaved: true
                });
            });   
        });
    }
    uploadFile = files => {
        let stateData = this.state.data;
        let t = this;
        t.setState({loading:true, isSaved: false});

        var reader = new FileReader();
        reader.onload = function(e) {
            csv.parse(reader.result, (err, data) => {
                if(data.length==1026){
                    //Skip header
                    for(let i=1; i<data.length; i++){
                        for(let y=0; y<stateData.length; y++){
                            if(Number(data[i][0])===stateData[y].Id && Number(data[i][3]) >=-1 && Number(data[i][3])<=1){
                                stateData[y].Message=data[i][2] || "";
                                stateData[y].Priority=Number(data[i][3]);
                            }
                        }
                    }

                    t.setState({
                        data: stateData.length>0?stateData:[],
                        loading:false
                    });
                }
            });
        }
        reader.readAsText(files[0]);
    }


    faultTag = (i)=>{
        var bit = i%32;
        var index = (i-bit)/32;
        return "MLFault["+index+"]."+bit
    }
    onChange = (e) => {
        let data = this.state.data;
        
        if(e.target.name in data){
            data[e.target.name]=e.target.value;
            this.setState({ data: data});  
        }

    };
    setMessage = (e, i)=>{
        let data = this.state.data;
        if(i>=0 && i<data.length){
            data[i].Message=e.target.value;
            this.setState({ data: data, isSaved: false});  
        }
    }
    setPrioriy = (e,index,priority)=>{
        var {data} = this.state;
        for(let i=0;i<data.length; i++){
            if(i==index){
                data[i].Priority=priority;
            }
        }
        this.setState({ data: data, isSaved: false});  
    }
    table = () => {    
        if(this.state.loading){
                return (
                    <main role="main" className="container">
                        <div className="w-100 text-center">
                            <h1 className="h3 mb-3 font-weight-normal">Loading default alarms...</h1>
                            <div className="spinner-grow" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </main>
                )
            }
            else{
                return (
                    <table className="table table-striped">
                    <thead className="thead-dark">
                        <tr>
                        <th scope="col" className="text-left">Code</th>
                        <th scope="col" className="text-center">Message</th>
                        <th scope="col" className="text-center">Priority</th>
                        <th scope="col" className="text-center">Tag</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.data.map((row, i) => {
                        return (
                        <tr key={i}>
                            <th scope="row" className="align-middle">[{row.FaultCode}]</th>
                            <td className="p-1 m-0 align-middle"><input type="text" onChange={e => this.setMessage(e, i)} className="form-control m-0 mt-1" value={(row.Message || "").toUpperCase()}/></td>
                            <td  className="text-center">
                                <i onClick={e => this.setPrioriy(e, i,-1)} className={"fe fe-message-circle "+(row.Priority==-1?"text-success":"text-muted")} style={{padding: "1rem", fontSize: "2rem", cursor:"pointer"}}></i>
                                <i onClick={e => this.setPrioriy(e, i,0)} className={"fe fe-help-circle "   +(row.Priority==0?"text-warning":"text-muted")}  style={{padding: "1rem", fontSize: "2rem", cursor:"pointer"}}></i>
                                <i onClick={e => this.setPrioriy(e, i,1)} className={"fe fe-alert-circle "  +(row.Priority==1?"text-danger":"text-muted")}  style={{padding: "1rem", fontSize: "2rem", cursor:"pointer"}}></i>
                            </td>
                            <td  className="text-center align-middle">{this.faultTag(row.FaultCode)}</td>
                        </tr>)
                    })}
                    </tbody>
                    </table>
                )
            }
    }
    render(){
        var headers = [
            { label: "Id", key: "Id" },
            { label: "FaultCode", key: "FaultCode" },
            { label: "Message", key: "Message" },
            { label: "Priority", key: "Priority" }
        ];
        return (
            <main role="main" className="container">
                <h2><i className="fe fe-bell"></i> Alarms</h2>
                <i>List of standardized alarms</i>
                <div className="row form-group mt-3">
                    <div className="col p-0">
                        <form className="form-inline">
                            <button type="button" className="btn btn-success mr-1" onClick={this.saveBtn}>Save</button>                       
                            <input className="form-control ml-1" type="search" onChange={this.onSearch} placeholder="Search" aria-label="Search"/>                      
                        </form>
                    </div>
                    <div className="col p-0">
                        <CSVLink data={this.state.data} headers={headers} filename={"mlink-default-faults.csv"}>
                            <button type="button" className="btn btn-info float-right ml-1 mr-1">Export</button>
                        </CSVLink>
                        <ReactFileReader handleFiles={this.uploadFile} fileTypes={'.csv'}>
                            <button type="button" className="btn btn-warning float-right ml-1 mr-1">Import</button>
                        </ReactFileReader>
                    </div>
                </div>
                {this.state.isSaved?(<div/>):(
                <div className="row mb-2">
                    <i className="ml-3 text-danger">Data not saved!</i>
                </div>
                )}
                <div className="row">
                    {this.table()}

                </div>
            </main>

            
        )
    }
}
export default Containers;