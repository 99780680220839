import React, { Component } from 'react';
import {Route, Redirect, Switch} from "react-router-dom";
import IssueSummary from './Reports/IssueSummary.js'

class Systems extends Component {
    render(){
        const {props}=this;
        const access = localStorage.getItem("access");
        return (
                <main role="main" className="container">
                        <Switch>
                            {access>10?(<Route exact path="/r/issue/summary/:SiteId" render={({match})=>(<IssueSummary {...match} {...props} />)}/>):(<Redirect to="/home"/>)}
                            <Route path="/" render={()=>(<Redirect to="/home"/>)}/>
                        </Switch>
                </main>          
        )
    }
}
export default Systems;