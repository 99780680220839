import React, { Component } from 'react';
import {Link} from "react-router-dom";


class IssuesAll extends Component {
    state = {
        loading: true,
        search: "",
        data: []
    }
    onSearch = (event) => {
        const {databaseRequest}=this.props;
        this.setState({loading: true});
        var search = event.target.value;
        databaseRequest(`/issues?State=2&Title:like=%${search}%`,"GET").then((data)=>{
                this.setState({
                    loading: false,
                    data: data.length>0?data:[]
                })
        });
    }
    siteName = (Id) =>{
        const {sites} = this.state;
        for(let i=0; i<sites.length; i++){
            if(Id==sites[i].Id){
                return sites[i].Name;
            }
        }
        return "Not site related";
    }
    userName = (Id) =>{
        const {users} = this.state;
        for(let i=0; i<users.length; i++){
            if(Id==users[i].Id){
                return users[i].Name;
            }
        }
        return "-";
    }
    stateColor = (state) =>{
        switch(state){
            case 0:
                return "#ddf4ff";
                break;
            case 1:
                return "inherit";
                break;
            case 2:
                return "#ffdddd";
                break;
            case 3:
                return "#fff8dd";
                break;            
            case 4:
                return "#ddffdd";
            break;
        }
        return "inherit";
    }
    stateName = (state) =>{
        switch(state){
            case 0:
                return "Created";
                break;
            case 1:
                return "Viewed";
                break;
            case 2:
                return "Outstanding";
                break;
            case 3:
                return "Underway";
                break;            
            case 4:
                return "Resolved";
            break;
        }
        return "Comment";
    }
    timeAgo = (time) => {
        var d = new Date().getTime();
        var elapsed = d-time;
        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 60; //Two months
        var msPerYear = msPerDay * 365*2; //Two years
        if(elapsed < 10000){
            return "Now";
        }
        if (elapsed < msPerMinute) {
            return `${Math.round(elapsed/1000)} second${Math.round(elapsed/1000)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerHour) {
            return `${Math.round(elapsed/msPerMinute)} minute${Math.round(elapsed/msPerMinute)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerDay ) {
            return `${Math.round(elapsed/msPerHour)} hour${Math.round(elapsed/msPerHour)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerMonth) {
            return `${Math.round(elapsed/msPerDay)} day${Math.round(elapsed/msPerDay)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerYear) {
            return `${Math.round(elapsed/msPerMonth)} month${Math.round(elapsed/msPerMonth)>1?"s":""} ago`;   
        }

        else {
            return `${Math.round(elapsed/msPerYear)} year${Math.round(elapsed/msPerYear)>1?"s":""} ago`;   
        }
    }
    componentDidMount = () =>{
        const {databaseRequest,setPage}=this.props;
        setPage(`Issues - Outstanding`);
        let sites = [];
        let users = [];
        databaseRequest("/system/sites","GET").then((data)=>{ 
            sites = [...data];
            return databaseRequest("/users","GET");  
        }).then((data)=>{
            users = [...data];
            return databaseRequest("/issues?State=2","GET");
        }).then((data)=>{
            this.setState({
                loading: false,
                data: data.length>0?data:[],
                sites: sites,
                users: users
            })

        });
    }
    priority = (priority)=>{
        switch(priority){
            case 0:
                return "Low";
                break;
            case 1:
                return "Medium";
                break;
            case 2:
                return "High";
                break;
            case 3:
                return "Urgent";
                break;            
        }
        return "-";
    }
    table = () => {    
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading issues...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    <table className="table table-striped">
                    <thead className="thead-dark">
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Title</th>
                        <th scope="col">Priority</th>
                        <th scope="col">Site</th>
                        <th scope="col" className="d-none d-md-table-cell">Category</th>
                        <th scope="col" className="d-none d-md-table-cell">Who/When</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.data.map((row, i) => {
                        return (
                        <tr key={i} style={{background: this.stateColor(row.State)}}>
                            <th scope="row" >{1+i}</th>
                            <td><Link to={`/issues/registry/${row.Id}`}>{row.Title}</Link></td>
                            <td>{this.priority(row.Priority)}</td>
                            <td>{this.siteName(row.AllocatedSiteId)}</td>
                            <td className="d-none d-md-table-cell">{(row.Category)}</td>
                            <td className="d-none d-md-table-cell">{this.userName(row.AllocatedTo)+" - "+this.timeAgo(row.DateTime)}</td>
                        </tr>)
                    })}
                    </tbody>
                    </table>
                )
            }
    }

    render(){
        return (
            <main role="main" className="container">
                <h2><i className="fe fe-x-circle"></i> Issues - Outstanding</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <form className="form-inline">
                            <input className="form-control mr-sm-2" type="search" onChange={this.onSearch} placeholder="Search" aria-label="Search"/>                      
                        </form>
                    </div>
                    <div className="col p-0">
                        <Link to="/issues/registry/-1"><button type="button" className="btn btn-success float-right">New Issue</button></Link>
                    </div>
                </div>
                <div className="row">
                    {this.table()}

                </div>
            </main>

            
        )
    }
}
export default IssuesAll;