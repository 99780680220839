import React, { Component } from 'react';
import {Link} from "react-router-dom";
class UsersAll extends Component {
    state = {
        loading: true,
        isSaved: true,
        users:[],
        sites:[],
        containers:[],
        faults: [],
        UserId:-1
    }
    componentDidMount = async () =>{
        const {databaseRequest,setPage,params}=this.props;
        setPage(`Subscriptions - System`);
        var sites = await databaseRequest("/system/sites","GET");
        var containers = await databaseRequest("/system/containers","GET");
        var users = await databaseRequest("/users","GET");
        var defaultFaults = await databaseRequest(`/system/faults/default?limit=2000`,"GET");
        var specificFaults = await databaseRequest(`/system/faults?ContainerId=${params.ContainerId}`,"GET");
        var subscriptions = await databaseRequest(`/system/faults/subscription/${params.UserId}/${params.ContainerId}`, 'GET');

        for(let i=0; i<defaultFaults.length; i++){
            defaultFaults[i].IsSpecific=false;
            defaultFaults[i].Email=0;
            defaultFaults[i].Pushover=0;
            for(let j=0; j<specificFaults.length; j++){
                if(defaultFaults[i].FaultCode==specificFaults[j].FaultCode){
                    defaultFaults[i].Message=specificFaults[j].Message;
                    defaultFaults[i].Priority=specificFaults[j].Priority;
                    defaultFaults[i].IsSpecific=true;
                }
            }
            for(let j=0; j<subscriptions.length; j++){
                if(defaultFaults[i].FaultCode==subscriptions[j].FaultCode){
                    if(subscriptions[j].NotificationType==1){
                        defaultFaults[i].Pushover=subscriptions[j].TriggerType;
                    }
                    if(subscriptions[j].NotificationType==2){
                        defaultFaults[i].Email=subscriptions[j].TriggerType;
                    }
                }
            }

        }

        this.setState({
            loading: false,
            sites: sites.length>0?sites:[],
            containers: containers.length>0?containers:[],
            users: users.length>0?users:[],
            faults: defaultFaults.length>0?defaultFaults:[],
            UserId: params.UserId
        });
        document.addEventListener('contextmenu', function(event){
            event.preventDefault();
        });
    }
    saveBtn = ()=>{
        const {databaseRequest, params}=this.props;
        let t = this;
        t.setState({loading:true, isSaved: false});

        var data = [];
        for(let i=0; i<this.state.faults.length; i++){
            if(this.state.faults[i].Email>0 || this.state.faults[i].Pushover>0){
                data.push(this.state.faults[i]);
            }
        }

        databaseRequest(`/system/faults/subscription/${params.UserId}/${params.ContainerId}`,"POST", 
            JSON.stringify(data)
        ).then((data)=>{
            t.setState({
                loading: false, 
                isSaved: true
            });
        });
    }
    username = (Id)=>{
        for(let i=0; i<this.state.users.length; i++){
            if(this.state.users[i].Id==Id){
                return this.state.users[i].Name;
            }
        }
        return "NA"
    }
    siteName = (Id)=>{
        for(let i=0; i<this.state.sites.length; i++){
            if(this.state.sites[i].Id==Id){
                return this.state.sites[i].Name;
            }
        }
        return "NA"
    }
    systemName = ()=>{
         const {ContainerId} = this.props.params;
         for(let i=0; i<this.state.containers.length; i++){
             if(this.state.containers[i].Id==ContainerId){           
                return `[${this.siteName(this.state.containers[i].SiteId)}/${this.state.containers[i].Name}]`
             }
         }
         return "NA"
    }
    tagCloud = () =>{
        if(this.state.loading){
            return (
                <div className="w-100 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">Loading systems...</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )
        }
        else{
            return (
            <div>    
                <div className="row mb-4">
                    <Link to={`/faults/subscriptions/`}><button type="button" className="btn btn-primary mr-1">{this.username(this.state.UserId)}</button></Link>
                    <Link to={`/faults/subscriptions/${this.state.UserId}`}><button type="button" className="btn btn-primary mr-1">{this.systemName(this.state.ContainerId)}</button></Link>


                    {this.state.isSaved?(<div/>):(
                    <button type="button" className="btn btn-success ml-3" onClick={this.saveBtn}>Save</button>   
                    )} 

                        <div className="col p-0">
                            <Link to={`/faults/subscriptions/${this.state.UserId}`}><button type="button" className="btn btn-info float-right">Back to System Selection</button></Link>
                        </div>
                    </div>    
                
                {this.state.isSaved?(<div/>):(
                <div className="row mb-2">
                    <i className="ml-3 text-danger">Data not saved!</i>
                </div>
                )}
                <div className="row">
                    {this.table()}
                </div>
            </div>
            );
        }
    }
    triggerIcon = (Type)=>{
        if(Type==0){return (<i className="fe fe-bell-off text-muted" style={{padding: "1rem", fontSize: "2rem", cursor:"pointer"}}></i>);}
        if(Type==1){return (<i className="fe fe-arrow-up-right text-success" style={{padding: "1rem", fontSize: "2rem", cursor:"pointer"}}></i>);}
        if(Type==2){return (<i className="fe fe-arrow-down-right text-success" style={{padding: "1rem", fontSize: "2rem", cursor:"pointer"}}></i>);}
        if(Type==3){return (<i className="fe fe-shuffle text-success" style={{padding: "1rem", fontSize: "2rem", cursor:"pointer"}}></i>);}
    }
    setEmail = (e,FaultCode,inc)=>{
        var faults = this.state.faults;
        for(let i=0; i<faults.length; i++){
            if(faults[i].FaultCode===FaultCode){
                faults[i].Email+=inc;
                faults[i].Email=faults[i].Email>3?0:faults[i].Email;
                faults[i].Email=faults[i].Email<0?3:faults[i].Email;
            }
        }
        this.setState({faults:faults, isSaved:false});
    }
    setPushover = (e,FaultCode,inc)=>{
        var faults = this.state.faults;
        for(let i=0; i<faults.length; i++){
            if(faults[i].FaultCode===FaultCode){
                faults[i].Pushover+=inc;
                faults[i].Pushover=faults[i].Pushover>3?0:faults[i].Pushover;
                faults[i].Pushover=faults[i].Pushover<0?3:faults[i].Pushover;
            }
        }
        this.setState({faults:faults, isSaved:false});
    }
    table = () => {    
        if(this.state.loading){
                return (
                    <main role="main" className="container">
                        <div className="w-100 text-center">
                            <h1 className="h3 mb-3 font-weight-normal">Loading default alarms...</h1>
                            <div className="spinner-grow" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </main>
                )
            }
            else{
                return (
                    <table className="table table-striped">
                    <thead className="thead-dark">
                        <tr>
                        <th scope="col" className="text-left">Code</th>
                        <th scope="col" className="text-center">Message</th>
                        <th scope="col" className="text-center">E-Mail</th>
                        <th scope="col" className="text-center">Pushover</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.faults.map((row, i) => {
                        if(row.Message.length > 0){
                            return (
                            <tr key={i}>
                                <th scope="row" className="align-middle">[{row.FaultCode}]</th>
                                <td className={row.IsSpecific?"p-1 m-0 align-middle text-danger":"p-1 m-0 align-middle"}>{row.Message.toUpperCase()+(row.IsSpecific?" (SITE SPECIFIC)":"")}</td>
                                <td className="text-center align-middle" onContextMenu={e => this.setEmail(e, row.FaultCode,-1)} onClick={e => this.setEmail(e, row.FaultCode,1)}>{this.triggerIcon(row.Email)}</td>
                                <td className="text-center align-middle" onContextMenu={e => this.setPushover(e, row.FaultCode,-1)} onClick={e => this.setPushover(e, row.FaultCode,1)}>{this.triggerIcon(row.Pushover)}</td>
                            </tr>)
                        }
                    })}
                    </tbody>
                    </table>
                )
            }
    }
    render(){
        return (
            <main role="main" className="container">
                <h2><i className="fe fe-bell"></i> System Selection <i className="fe fe-alert-circle"></i></h2>
                <div className="row">
                    <div className="col p-0">
                        <p>Assign system to subscription.</p>
                    </div>
                </div>

                
                {this.tagCloud()}
            </main>

            
        )
    }
}

export default UsersAll;