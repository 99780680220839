import React, { Component, createRef } from 'react';
import { Link } from "react-router-dom";
import socketIOClient from "socket.io-client";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import option from './GaugeOptions.js';
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);

class Dashboard extends Component {
    state = {
        loading: true,
        data: [],
        updateInt: null,
        c1: null,
        Container: "",
        Site: "",
        Rinsed: 0,
        Weighed: 0,
        Species: "",
        hhmmss: 0,
        yyyymmdd: 0,
        State: 0,
        age: 0,
        FlowRates: []
        
    }
    age = (values) =>{
        var maxAge = 10000000000;
        for (let i = 0; i < values.length; i++) {
            if (maxAge > values[i].Age) {
                maxAge = values[i].Age;
            }
        }
        return maxAge;
    }
    getValue = (tag, values) => {
        for (let i = 0; i < values.length; i++) {
            if (tag == values[i].Tag) {
                return values[i];
            }
        }
        return [];
    }
    componentWillUnmount() {
        clearInterval(this.state.updateInt);
    }
    async componentDidMount() {
        const { databaseRequest, params, setPage , socketServer} = this.props;
        var { Token, Species } = params;
        Species = Species.replace(/^\w/, c => c.toUpperCase());
        let t = this;
        //alert(Token + ", " + Species);
        setPage("Dashboard");
        var data = await databaseRequest(`/system/containers/t/${Token}`,`GET`,null,false,Token);
        var Container = data.Name;
        var Site = data.SiteName;
        const socket = socketIOClient(socketServer);
            var TAGS = [];
                for(let j=0; j<32; j++){
                    TAGS.push(`[${Container}]MLFlowRates[${j}]`);
                }
                TAGS.push(`[${Container}]MLLive${Species}.hhmmss`);
                TAGS.push(`[${Container}]MLLive${Species}.yyyymmdd`);
                TAGS.push(`[${Container}]MLLive${Species}.State`);
                TAGS.push(`[${Container}]MLLive${Species}.Weighed`);
                TAGS.push(`[${Container}]MLLive${Species}.Rinsed`);
                TAGS.push(`[${Container}]MLLive${Species}.T1_Conductivity`);
                TAGS.push(`[${Container}]MLLive${Species}.T1_Level`);
                TAGS.push(`[${Container}]MLLive${Species}.T1_Temperature`);
                TAGS.push(`[${Container}]MLLive${Species}.T2_Conductivity`);
                TAGS.push(`[${Container}]MLLive${Species}.T2_Level`);
                TAGS.push(`[${Container}]MLLive${Species}.T2_Temperature`);
                TAGS.push(`[${Container}]MLLive${Species}.RinseboxTemp`);

            var request = function(){
                socket.emit("request", TAGS, function (values) {
                    var FlowRates = [];
                    for(let i=0; i<32; i++){
                        FlowRates.push(0);
                    }
                    for(let i=0; i<values.length;i++){
                        for(let j=0; j<32; j++){        
                            if(`[${Container}]MLFlowRates[${j}]` === values[i].Tag){
                                FlowRates[j]=values[i].Value;
                            }        
                        }
                    }
                    t.refs.c3.chart.series[0].points[0].update(t.getValue(`[${Container}]MLLive${Species}.T1_Conductivity`, values).Value);
                    t.refs.c1.chart.series[0].points[0].update(t.getValue(`[${Container}]MLLive${Species}.T1_Level`, values).Value);
                    t.refs.c2.chart.series[0].points[0].update(t.getValue(`[${Container}]MLLive${Species}.T1_Temperature`, values).Value);
                    t.refs.c6.chart.series[0].points[0].update(t.getValue(`[${Container}]MLLive${Species}.T2_Conductivity`, values).Value);
                    t.refs.c4.chart.series[0].points[0].update(t.getValue(`[${Container}]MLLive${Species}.T2_Level`, values).Value);
                    t.refs.c5.chart.series[0].points[0].update(t.getValue(`[${Container}]MLLive${Species}.T2_Temperature`, values).Value);
                    t.refs.c7.chart.series[0].points[0].update(t.getValue(`[${Container}]MLLive${Species}.RinseboxTemp`, values).Value);
                    t.setState({
                        Species: Species,
                        Rinsed: t.getValue(`[${Container}]MLLive${Species}.Rinsed`, values).Value,
                        Weighed: t.getValue(`[${Container}]MLLive${Species}.Weighed`, values).Value,
                        hhmmss: t.getValue(`[${Container}]MLLive${Species}.hhmmss`, values).Value,
                        yyyymmdd: t.getValue(`[${Container}]MLLive${Species}.yyyymmdd`, values).Value,
                        State: t.getValue(`[${Container}]MLLive${Species}.State`, values).Value,
                        age: t.age(values),
                        FlowRates: FlowRates
                    })
                });
            }
            request();
            this.setState({
                loading: false,
                Container: Container,
                Site: Site,
                updateInt:
                    setInterval(function () {
                        request();
                    }, 5000)
            });
    }

    loading = () => {
        return (
            <main role="main" className="container" style={{paddingTop:"0px"}}>
            <div className="w-100 mt-5 text-center">
                <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
            </main>
        )
    }
    page = () => {
        const {FlowRates} = this.state;
        if (this.state.loading) {
            return this.loading();
        }

        return (
            <div>
                <main role="main" className="container" style={{paddingTop:"0px"}}>
                    <div className="">
                        <div className="p-5">
                            <div className="row form-group">
                                <div className="col p-0">
                                    <h1 className="border-bottom mb-0">[{this.state.Container}] {this.state.Site} / {this.state.Species}</h1>
                                    <h3 className="text-danger mt-1">{this.connectionQuality(this.state.age)}</h3>
                                </div>
                                <div className="col p-0">
                                <h1 className="border-bottom text-right mb-0">{this.DINTtoLongDate(this.state.yyyymmdd)}</h1>
                                <h3 className="text-right mt-0">{this.DINTtoTime(this.state.hhmmss)}</h3>
                                </div>
                            </div>
                            <div className="row">
                                <form className="w-100">
                                    <div className="form-row">
                                        <div className="form-group col-sm">
                                            <table className="table">
                                                <tbody>
                                                    <tr><th>% Rinsed</th><td>{((this.state.Weighed>0?(this.state.Rinsed/this.state.Weighed):0)*100).toFixed(2)}%</td></tr>
                                                    <tr><th>Weighed</th><td>{this.state.Weighed}</td></tr>
                                                    <tr><th>Rinsed</th><td>{this.state.Rinsed}</td></tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="form-group col-sm">
                                            <table className="table">
                                                <tbody>
                                                    <tr><th>In Production</th><td>{this.bit(this.state.State,0)?(<h1 className="fe text-success fe-check-circle m-0"></h1>):(<div/>)}</td></tr>
                                                    <tr><th>In CIP</th><td>{this.bit(this.state.State,1)?(<h1 className="fe text-success fe-check-circle m-0"></h1>):(<div/>)}</td></tr>
                                                    <tr><th>Faulted</th><td>{this.bit(this.state.State,2)?(<h1 className="fe text-danger fe-alert-circle m-0"></h1>):(<div/>)}</td></tr>
                                                </tbody>    
                                            </table>
                                        </div>
                                        <div className="form-group col-sm">
                                         <HighchartsReact
                                                containerProps={{ style: { height: "200px" } }}
                                                options={option.RinseTemp}
                                                highcharts={Highcharts}
                                                allowChartUpdate={true}
                                                ref="c7"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row"><h2>T1 - Concentrate Tank</h2></div>
                                    <div className="form-row">
                                        
                                        <div className="form-group col-sm">
                                            <HighchartsReact
                                                containerProps={{ style: { height: "200px" } }}
                                                options={option.T1Level}
                                                highcharts={Highcharts}
                                                allowChartUpdate={true}
                                                ref="c1"
                                            />
                                        </div>
                                        <div className="form-group col-sm">
                                            <HighchartsReact
                                                containerProps={{ style: { height: "200px" } }}
                                                options={option.T1Temp}
                                                highcharts={Highcharts}
                                                allowChartUpdate={true}
                                                ref="c2"
                                            />
                                        </div>
                                        <div className="form-group col-sm">
                                            <HighchartsReact
                                                containerProps={{ style: { height: "200px" } }}
                                                options={option.T1Cond}
                                                highcharts={Highcharts}
                                                allowChartUpdate={true}
                                                ref="c3"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row"><h2>T2 - Production Tank</h2></div>
                                    <div className="form-row">
                                        <div className="form-group col-sm">
                                            <HighchartsReact
                                                containerProps={{ style: { height: "200px" } }}
                                                options={option.T2Level}
                                                highcharts={Highcharts}
                                                allowChartUpdate={true}
                                                ref="c4"
                                            />
                                        </div>
                                        <div className="form-group col-sm">
                                            <HighchartsReact
                                                containerProps={{ style: { height: "200px" } }}
                                                options={option.T2Temp}
                                                highcharts={Highcharts}
                                                allowChartUpdate={true}
                                                ref="c5"
                                            />
                                        </div>
                                        <div className="form-group col-sm">
                                            <HighchartsReact
                                                containerProps={{ style: { height: "200px" } }}
                                                options={option.T2Cond}
                                                highcharts={Highcharts}
                                                allowChartUpdate={true}
                                                ref="c6"
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="row">
                                <div className="form-row">
                                    <div className="form-group col-sm">
                                        <h2>Flow Rates</h2>
                                        <table className="table m-0">
                                            <tbody>
                                            {FlowRates.map((row, i) => {
                                                if(row>0){
                                                return (
                                                <tr key={i}><th className="">Hose {i}</th><td className="text-right">{row.toFixed(2)}l/m</td></tr>
                                                )
                                                }    
                                            })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
    render() {
        return this.page();
    }
    connectionQuality = (elapsed) => {
        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 60; //Two months
        var msPerYear = msPerDay * 365*2; //Two years
        if(elapsed <= 0){
            return "Not Connected"
        }
        if(elapsed < 10000){
            return "";
        }
        if(elapsed < 31000){
            return "Connected: Not Polling";
        }
        if (elapsed < msPerMinute) {
            return `Warning: Updated ${Math.round(elapsed/1000)} second${Math.round(elapsed/1000)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerHour) {
            return `Warning: Updated ${Math.round(elapsed/msPerMinute)} minute${Math.round(elapsed/msPerMinute)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerDay ) {
            return `Warning: Updated ${Math.round(elapsed/msPerHour)} hour${Math.round(elapsed/msPerHour)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerMonth) {
            return `Warning: Updated ${Math.round(elapsed/msPerDay)} day${Math.round(elapsed/msPerDay)>1?"s":""} ago`;   
        }

        else if (elapsed < msPerYear) {
            return `Warning: Updated ${Math.round(elapsed/msPerMonth)} month${Math.round(elapsed/msPerMonth)>1?"s":""} ago`;   
        }

        else {
            return `Warning: Updated ${Math.round(elapsed/msPerYear)} year${Math.round(elapsed/msPerYear)>1?"s":""} ago`;   
        }
    }
    DINTtoLongDate = function(dint){
        if(typeof dint==='undefined' || dint==0){
            return " - ";
        }
        var dd=Number(this.pad(dint).substr(6,2));
        var mm=Number(this.pad(dint).substr(4,2));
        var yyyy=Number(this.pad(dint).substr(0,4));
    
        var month = new Array();
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";
        
        return month[mm-1]+" "+this.ordinal_suffix_of(dd)+", "+yyyy;
    }  
    ordinal_suffix_of = function(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }
    DINTtoDate = function (dint) {
        if(typeof dint==='undefined'){
            return "";
        }
        return this.pad(dint, 8).substr(6, 2) + '/' + this.pad(dint, 8).substr(4, 2) + '/' + this.pad(dint, 8).substr(0, 4);
    }
    DINTtoTime = function (dint) {
        if(typeof dint==='undefined'){
            return "";
        }
        return this.pad(dint, 6).substr(0, 2) + ':' + this.pad(dint, 6).substr(2, 2) + ':' + this.pad(dint, 6).substr(4, 2);
    }
    pad = function (num, places = 2) {
        var str = num.toString();
        while (str.length < places) {
            str = "0" + str;
        }

        return str;
    }

    roundToPlaces = function (num, places) {
        var div = "1";
        if (places < 0) { return 0; }
        if (places > 10) { return 0; }
        for (let i = 0; i < places; i++) {
            div = div + "0";
        }
        var base = Number(div);
        return Math.round(num * base) / base;
    }
    bit = function (num, place) {
        var mask = 1 << place;
        return (num & mask) != 0;
    }
}

export default Dashboard;