import React, { Component } from 'react';

class Copyright extends Component {
    state = {
      from: 2018,
      to: 0
    }
    componentDidMount(){
        var d = new Date();
        this.setState({
            to: d.getFullYear()
        })
    }
    render (){

      return (
        <p className="mt-5 mb-3 text-muted">&copy; {this.state.from}-{this.state.to} MPSC Australia Pty. Ltd.</p>
      );
    }
  }
  export default Copyright;