import React, { Component, createRef } from 'react';
import { Link } from "react-router-dom";

class Dashboard extends Component {
    state = {
        loading: true,
        data: [],
        updateInt: null,
        Outstanding: 0,
        Underway: 0,
        Unviewed: 0,
        Resolved: 0
    }
    age = (values) => {
        var maxAge = 10000000000;
        for (let i = 0; i < values.length; i++) {
            if (maxAge > values[i].Age) {
                maxAge = values[i].Age;
            }
        }
        return maxAge;
    }
    componentWillUnmount() {
        clearInterval(this.state.updateInt);
    }
    componentDidMount = () => {
        const { databaseRequest } = this.props;
        databaseRequest("/issues", "GET").then((data) => {
            var Outstanding = 0;
            var Underway = 0;
            var Unviewed = 0;
            var Resolved = 0;
            for (let i = 0; i < data.length; i++) {
                Resolved += data[i].State == 4 ? 1 : 0;
                Outstanding += data[i].State == 2 ? 1 : 0;
                Underway += (data[i].State == 3 || data[i].State == 1) ? 1 : 0;
                Unviewed += data[i].State == 0 ? 1 : 0;
            }
            this.setState({
                loading: false,
                Outstanding: Outstanding,
                Underway: Underway,
                Unviewed: Unviewed,
                Resolved: Resolved
            })
        });
    }

    page = () => {
        return (
            <div>
            </div>
        );
    }
    table = () => {
        if (this.state.loading) {
            return (
                <div className="w-100 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">Loading issues...</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="p-3 shadow bg-white rounded">
                    <h2><i className="fe fe-alert-circle"></i> Issues</h2>
                    <table className="table">
                        <tbody>
                            <tr>
                                <td className="p-0 border-0">
                                    <div className="bg-danger text-white m-1" style={{ borderRadius: "6px" }}>
                                    <table><tbody><tr><th className="border-0">Outstanding</th>
                                    <td className="text-right border-0 w-100">{this.state.Outstanding}</td></tr></tbody></table>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="p-0 border-0">
                                    <div className="bg-warning text-dark m-1" style={{ borderRadius: "6px" }}>
                                    <table><tbody><tr><th className="border-0">Underway</th>
                                    <td className="text-right border-0 w-100">{this.state.Underway}</td></tr></tbody></table>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="p-0 border-0">
                                    <div className="bg-info text-white m-1" style={{ borderRadius: "6px" }}>
                                    <table><tbody><tr><th className="border-0">Unviewed</th>
                                    <td className="text-right border-0 w-100">{this.state.Unviewed}</td></tr></tbody></table>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="p-0 border-0">
                                    <div className="bg-success text-white m-1" style={{ borderRadius: "6px" }}>
                                    <table><tbody><tr><th className="border-0">Resolved</th>
                                    <td className="text-right border-0 w-100">{this.state.Resolved}</td></tr></tbody></table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        }
    }

    render() {
        return (
            <div>{this.table()}</div>
        )
    }
    siteName = (Id) => {
        const { sites } = this.state;
        for (let i = 0; i < sites.length; i++) {
            if (Id == sites[i].Id) {
                return sites[i].Name;
            }
        }
        return "Not site related";
    }
    userName = (Id) => {
        const { users } = this.state;
        for (let i = 0; i < users.length; i++) {
            if (Id == users[i].Id) {
                return users[i].Name;
            }
        }
        return "-";
    }
    stateColor = (state) => {
        switch (state) {
            case 0:
                return "#ddf4ff";
                break;
            case 1:
                return "inherit";
                break;
            case 2:
                return "#ffdddd";
                break;
            case 3:
                return "#fff8dd";
                break;
            case 4:
                return "#ddffdd";
                break;
        }
        return "inherit";
    }
    stateName = (state) => {
        switch (state) {
            case 0:
                return "Created";
                break;
            case 1:
                return "Viewed";
                break;
            case 2:
                return "Outstanding";
                break;
            case 3:
                return "Underway";
                break;
            case 4:
                return "Resolved";
                break;
        }
        return "Comment";
    }
    timeAgo = (time) => {
        var d = new Date().getTime();
        var elapsed = d - time;
        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 60; //Two months
        var msPerYear = msPerDay * 365 * 2; //Two years
        if (elapsed < 10000) {
            return "Now";
        }
        if (elapsed < msPerMinute) {
            return `${Math.round(elapsed / 1000)} second${Math.round(elapsed / 1000) > 1 ? "s" : ""} ago`;
        }

        else if (elapsed < msPerHour) {
            return `${Math.round(elapsed / msPerMinute)} minute${Math.round(elapsed / msPerMinute) > 1 ? "s" : ""} ago`;
        }

        else if (elapsed < msPerDay) {
            return `${Math.round(elapsed / msPerHour)} hour${Math.round(elapsed / msPerHour) > 1 ? "s" : ""} ago`;
        }

        else if (elapsed < msPerMonth) {
            return `${Math.round(elapsed / msPerDay)} day${Math.round(elapsed / msPerDay) > 1 ? "s" : ""} ago`;
        }

        else if (elapsed < msPerYear) {
            return `${Math.round(elapsed / msPerMonth)} month${Math.round(elapsed / msPerMonth) > 1 ? "s" : ""} ago`;
        }

        else {
            return `${Math.round(elapsed / msPerYear)} year${Math.round(elapsed / msPerYear) > 1 ? "s" : ""} ago`;
        }
    }
    priority = (priority) => {
        switch (priority) {
            case 0:
                return "Low";
                break;
            case 1:
                return "Medium";
                break;
            case 2:
                return "High";
                break;
            case 3:
                return "Urgent";
                break;
        }
        return "-";
    }
}

export default Dashboard;