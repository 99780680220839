import React, { Component } from 'react';
import {Link} from "react-router-dom";

class History extends Component {
    state = {
        loading: true,
        data: []
    }
    componentDidMount = async () =>{
        const {databaseRequest,UserId}=this.props;
        var sites = await databaseRequest("/system/sites","GET");
        var containers = await databaseRequest("/system/containers","GET");
        var defaultFaults = await databaseRequest(`/system/faults/default?limit=2000`,"GET");
        var specificFaults = await databaseRequest(`/system/faults`,"GET");
        var subscriptions = await databaseRequest(`/system/faults/subscription/${UserId}`, 'GET');
        
        this.setState({
            loading: false,
            sites: sites.length>0?sites:[],
            containers: containers.length>0?containers:[],
            defaultFaults: defaultFaults.length>0?defaultFaults:[],
            specificFaults: specificFaults.length>0?specificFaults:[],
            UserId: UserId
        });
        
        var faults = [];
        for(let i=0; i<subscriptions.length; i++){ 
            var fault = {
                ContainerId: subscriptions[i].ContainerId,
                System: this.systemName(subscriptions[i].ContainerId),
                Message: this.faultMessage(subscriptions[i].ContainerId, subscriptions[i].FaultCode),
                Type: subscriptions[i].NotificationType==1?"Pushover":(subscriptions[i].NotificationType==2?"E-mail":"NA"),
                TriggerType: subscriptions[i].TriggerType
            }
            faults.push(fault);
        }
        this.setState({data: faults})
    }
    faultMessage = (ContainerId, FaultCode)=>{
        const {defaultFaults,specificFaults}=this.state;
        var str = "NA";
        for(let i=0; i<defaultFaults.length; i++){
            if(defaultFaults[i].FaultCode==FaultCode){
                str = `[${FaultCode}] ${defaultFaults[i].Message}`;
            }
        }
        for(let i=0; i<specificFaults.length; i++){
            if(specificFaults[i].FaultCode==FaultCode && specificFaults[i].ContainerId==ContainerId){
                str = `[${FaultCode}] ${specificFaults[i].Message}`;
            }
        }
        return str;
    }
    systemName = (ContainerId)=>{
        const {sites,containers}=this.state;
        for(let i=0; i<containers.length; i++){
            for(let j=0; j<sites.length; j++){  
                if(containers[i].SiteId==sites[j].Id && containers[i].Id==ContainerId){
                    return `${sites[j].Name}/${containers[i].Name}`;
                }
            }
        }
        return "NA";
    }
    triggerIcon = (Type)=>{
        if(Type==0){return (<i className="fe fe-bell-off text-muted" style={{padding: "1rem", fontSize: "2rem", cursor:"pointer"}}></i>);}
        if(Type==1){return (<i className="fe fe-arrow-up-right text-success" style={{padding: "1rem", fontSize: "2rem", cursor:"pointer"}}></i>);}
        if(Type==2){return (<i className="fe fe-arrow-down-right text-success" style={{padding: "1rem", fontSize: "2rem", cursor:"pointer"}}></i>);}
        if(Type==3){return (<i className="fe fe-shuffle text-success" style={{padding: "1rem", fontSize: "2rem", cursor:"pointer"}}></i>);}
    }
    loading = () =>{
        return (
            <div className="w-100 mt-5 text-center">
                <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }
    page = () =>{
        return (    
                <div className="mt-5">       
                    <div className="row form-group">
                        <div className="col p-0">
                            <h2><i className="fe fe-bell"></i> Faults subscriptions</h2>
                        </div>
                    </div>
                    <div className="row">
                            {this.table()}
                    </div>
                </div> 
        );
    }
    table = () => {    
        const {UserId} = this.props;
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading subscriptions...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    <table className="table table-striped">
                    <thead className="thead-dark">
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">System</th>
                        <th scope="col">Message</th>
                        <th scope="col">Type</th>
                        <th scope="col">Trigger</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.data.map((row, i) => {
                        return (
                        <tr key={i}>
                            <th scope="row" >{1+i}</th>
                            <Link to={`/faults/subscriptions/${UserId}/${row.ContainerId}`}><td>{row.System}</td></Link>
                            <td>{row.Message}</td>
                            <td>{row.Type}</td>
                            <td>{this.triggerIcon(row.TriggerType)}</td>
                        </tr>)
                    })}
                    </tbody>
                    </table>
                )
            }
    }
    render() {
        return this.page();
    }
}

export default History;