import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

class NavDropdownItem extends Component {
    render (){
        return (
            <NavLink to={this.props.to} className="dropdown-item" onSelect={() => null}>
                <i className={`fe fe-${this.props.icon}`}></i> <span style={{marginLeft:3}}>{this.props.name}</span>
            </NavLink>
        );
    }
}

export default NavDropdownItem;