import React, { Component, createRef } from 'react';
import { Link } from "react-router-dom";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import option from '../Dashboards/GaugeOptions.js';
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/variable-pie')(Highcharts);


class Dashboard extends Component {
    state = {
        loading: true,
        data: [],
        updateInt: null
    }
    componentWillUnmount() {
        clearInterval(this.state.updateInt);
    }
    componentDidMount = () => {
        const { databaseRequest } = this.props;
    }
    render() {
        const { values } = this.props;
        var options = {
            chart: {
                type: 'variablepie',
                className: 'flex-grow',
                height: '70%'
            },
            title: {
                text: 'Daily Revenue by Site'
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
                    'Total Revenue (AUD): <b>${point.y:.2f}</b><br/>' +
                    'All bodies rinsed: <b>{point.z}</b><br/>'
            },
            series: [{
                minPointSize: 10,
                innerSize: '20%',
                zMin: 0,
                name: 'countries',
                data: []
            }]
        };
        for(let i=0; i<values.length; i++){
            options.series[0].data.push({
                name: values[i].Name,
                y: values[i].Revenue,
                z: values[i].Rinsed
            })
        }
        
        return (
            <div className="shadow bg-white rounded p-1 pb-3">     
            <HighchartsReact        
                options={options}
                highcharts={Highcharts}
                allowChartUpdate={true}
            />
            </div>
        );
    }

}

export default Dashboard;