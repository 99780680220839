import React, { Component } from 'react';
import md5 from 'md5';
import Copyright from '../Copyright.js';
import './SignIn.css';

class SignIn extends Component {
    state = {
        email: "",
        password: "",
        loading: true,
        failed: false,
        isMounted: false,
        timeout: null
    }
    onSubmit = (event) => {
        let t = this;
        const {databaseRequest,onSignIn}=this.props;

        event.preventDefault();
        t.setState({loading:true});

        databaseRequest("/login","POST",
            JSON.stringify({
                Email: this.state.email,
                Password: this.state.password
            })
        ).then((data)=>{
            if(data.length===1){
                if(data[0].Login<0){
                  t.setState({failed:true, loading:false});
                  setTimeout(function(){
                    t.setState({failed:false});
                  }, 100);
                }
                else{
                    console.log(data);
                    localStorage.setItem('token', data[0].Token);
                    localStorage.setItem('user', data[0].UserId);
                    localStorage.setItem('entity', data[0].EntityId);
                    localStorage.setItem('access', data[0].Access);
                  setTimeout(function(){
                    onSignIn(true);
                  }, 100);
                }
              }       
        })
    }
    componentDidMount = () => {
        let t = this;
        t.timeout = setTimeout(()=>{
            t.setState({loading:false}); 
        },1000);
    }
    componentWillUnmount = () =>{
        let t = this;
        clearTimeout(t.timeout);
    }
    /*componentDidMount = () => {
    let t = this;
    const {databaseRequest,onSignIn,setPage}=this.props;
    setPage("Login");
        databaseRequest(`/login/${sessionStorage.getItem('token')}`,'GET').then((data)=>{     
            if(data.length===1){
                if(data[0].Login!==-1){
                    setTimeout(function(){
                        onSignIn(true);
                        sessionStorage.setItem('token', data[0].Token);
                        sessionStorage.setItem('user', data[0].UserId);
                    }, 1000);  
                }
                else
                {
                    //onSignIn(false);
                    sessionStorage.clear();
                    t.setState({loading:false});
                }
            }
        });
    }*/
    render (){
      let container;  
      if(this.state.loading){
        container = (
            <div>
                <h1 className="h3 mb-3 font-weight-normal">Loading...</h1>
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
      }
      else{
        container = (
            <div>
                <label htmlFor="email" className="sr-only">Email address</label>
                <input type="email" onChange={(e) => { this.setState({ email: (e.target.value) }) }} id="email" className="form-control" placeholder="Email address" required autoFocus ></input>
                <label htmlFor="password" className="sr-only">Password</label>
                <input type="password" id="password" onChange={(e) => { this.setState({ password: md5(e.target.value) }) }} className="form-control" placeholder="Password" required></input>
                <button className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
            </div>
        );
      }
      return (
          <div className="signIn text-center">
              <form className="form-signin" onSubmit={this.onSubmit}>
                  <img className="mb-4" src="/mlink-solid.svg" alt="" width="80" height="80" />
                  
                   {container}
                  <Copyright/>
              </form>
          </div>
      );
    }
  }
  export default SignIn;
  